import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { ReactComponent as DeleteButton } from '../../assets/icons/circle-x-filled.svg';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'cfa-react-components';
import styles from './ProjectAdmin.module.scss';
import toastPromise from '../../helpers/toast-promise';

const pendingHandler = () => 'Deleting invitation...';

const successHandler = () => 'The invitation has been deleted.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);

const renderDeleteButton = (
  vendorId,
  projectId,
  email,
  setSelectedInvitation,
  showModal
) => {
  return (
    <DeleteButton
      onClick={() => {
        setSelectedInvitation({
          vendorId,
          projectId,
          email
        });
        showModal();
      }}
    />
  );
};

const renderModal = (
  showModal,
  closeModal,
  invitation,
  clearSelectedInvitation,
  deleteProjectInvitation
) => {
  let response;

  const deleteInvitation = async () => {
    const deleteProjectInvitationRequest = deleteProjectInvitation({
      vendorId: invitation.vendorId,
      projectId: invitation.projectId,
      values: { email: invitation.email }
    });

    try {
      response = await requestAndNotify(
        deleteProjectInvitationRequest.unwrap()
      );
    } catch (error) {}

    if (response?.status === 200 || response === '') {
      closeModal();
      clearSelectedInvitation();
    }
  };
  return (
    <>
      <Modal size="md" show={showModal} onClose={closeModal}>
        <ModalHeader>Delete Invited User</ModalHeader>
        <ModalBody>
          Are you sure you want to delete the invitation to{' '}
          <span className={styles.highlight}>{invitation?.email}</span>?
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>Cancel</Button>
          <Button onClick={deleteInvitation}>Delete</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ProjectUsers = ({
  rows,
  columns,
  vendorId,
  projectId,
  deleteProjectInvitation
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [selectedInvitation, setSelectedInvitation] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper
        sx={{ width: '50%', overflow: 'hidden', margin: 'auto' }}
        variant="outlined"
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, color: '#5b6770' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((invitedUser) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={invitedUser.invitationKey}
                    >
                      {columns.map((column) => {
                        const value = invitedUser[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ color: '#5b6770' }}
                          >
                            {column.id === 'delete' &&
                            ['INVITED', 'REGISTERED'].includes(
                              invitedUser.status
                            )
                              ? renderDeleteButton(
                                  vendorId,
                                  projectId,
                                  invitedUser.email,
                                  setSelectedInvitation,
                                  () => setShowModal(true)
                                )
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {renderModal(
        showModal,
        () => setShowModal(false),
        selectedInvitation,
        () => setSelectedInvitation({}),
        deleteProjectInvitation
      )}
    </>
  );
};

export default ProjectUsers;
