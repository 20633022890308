import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from 'cfa-react-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from './InvitationModal.module.scss';
import toastPromise from '../../../helpers/toast-promise';
import {
  usePostProjectInvitationMutation,
  usePostVendorInvitationMutation
} from '../../../store/invitationsApi';

const pendingHandler = () => 'Sending invitation...';

const successHandler = () =>
  'An invitation has been sent to the vendor at the requested email address.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required')
});

const initialValues = {
  email: ''
};

const InvitationModal = ({ showModal, handleClose, projectInvite }) => {
  const [postVendorInvitation] = usePostVendorInvitationMutation();
  const [postProjectInvitation] = usePostProjectInvitationMutation();

  const onFormikSubmit = async (values, { resetForm }) => {
    let response;

    const invitationRequest = projectInvite
      ? postProjectInvitation({
          vendorId: projectInvite.vendorId,
          projectId: projectInvite.projectId,
          values
        })
      : postVendorInvitation(values);

    try {
      response = await requestAndNotify(invitationRequest.unwrap());
    } catch (error) {}

    if (response?.status === 200 || response === '') {
      handleClose();
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onFormikSubmit
  });

  const dialogTitle = projectInvite
    ? 'Project Invitation'
    : 'Vendor Invitation';

  const dialogContextText = projectInvite
    ? 'To notify user of project access, please enter the contact email address here.'
    : 'To notify vendor of onboarding portal access, please enter the contact email address here.';

  return (
    <div className={styles['page-centered']}>
      <Dialog open={showModal} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <DialogContentText>{dialogContextText}</DialogContentText>
            <div className={styles['centered-container']}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className={styles['centered-container']}>
              <div>
                <Button
                  type="button"
                  size="small"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </div>
              <div>
                <Button type="submit" color="primary" size="small">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InvitationModal;
