import { useSelector } from 'react-redux';
import { useEffect, useState, useMemo } from 'react';
import Template from '../Template/Template';
import { useGetApisQuery } from '../../store/apisApi';
import { useUpdateProjectScopesMutation } from '../../store/projectsApi';
import { useTable } from 'react-table';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Checkbox
} from 'cfa-react-components';
import styles from './ManageScopes.module.scss';
import toastPromise from '../../helpers/toast-promise';

const renderModal = (
  showModal,
  closeModal,
  content,
  postScopeUpdates,
  projectId,
  projectName
) => {
  return (
    <Modal size="md" show={showModal} onClose={closeModal}>
      <ModalHeader>
        Submit Updated Scopes
        <div className={styles.modalProjectName}>{projectName}</div>
      </ModalHeader>
      <ModalBody>{content.markup}</ModalBody>
      <ModalFooter>
        <Button onClick={() => closeModal()}>Cancel</Button>
        <Button
          onClick={() =>
            postScopeUpdates(
              {
                projectId: projectId,
                scopeIds: content.ids
              },
              () => closeModal()
            )
          }
        >
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const formatTableData = (tableData) => {
  const content = {
    markup: [],
    ids: []
  };

  content.markup = tableData
    .filter((data) => data.assigned)
    .map((data) => {
      content.ids.push(data.id);
      return <div key={data.id}>{data.scopeName}</div>;
    });

  return content;
};

const mapApisAndProjectScopesToTableData = (apis, projectScopes) => {
  return apis.flatMap((api) => {
    return api.scopes.flatMap((scope) => {
      return {
        key: scope.scopeId,
        scopeName: scope.scopeName,
        id: scope.scopeId,
        assigned: !!projectScopes?.find(
          (projectScope) => projectScope.scopeId === scope.scopeId
        )
      };
    });
  });
};

const pendingHandler = () => 'Updating scopes...';
const successHandler = () => 'Scopes updated';
const requestAndNotify = toastPromise(pendingHandler, successHandler);

const ManageScopes = () => {
  const { data: apis = [], isLoading } = useGetApisQuery();

  const { currentProject } = useSelector((state) => state.projects);

  const [tableState, setTableState] = useState();

  const [showModal, setShowModal] = useState(false);

  const [updateProjectScopes] = useUpdateProjectScopesMutation();

  const { isAdmin } = useSelector((state) => state.auth);

  const postScopeUpdates = async (request, closeModal) => {
    try {
      await requestAndNotify(updateProjectScopes(request).unwrap());
      closeModal();
    } catch (error) {}
  };

  useEffect(() => {
    setTableState(
      mapApisAndProjectScopesToTableData(apis, currentProject?.scopes)
    );
  }, [apis, currentProject?.scopes]);

  const columns = useMemo(
    () => [
      {
        Header: 'Scope',
        accessor: 'scopeName', // accessor is the "key" in the data
        Cell: (props) => {
          return <div className={styles.scopeCell}>{props.value}</div>;
        }
      },
      {
        Header: 'Assigned',
        accessor: 'assigned',
        Cell: (props) => {
          return (
            <div className={styles.assignedCell}>
              <Checkbox
                checked={props.value}
                onChange={() =>
                  setTableState((prevState) => {
                    const newState = JSON.parse(JSON.stringify(prevState));
                    const index = newState.findIndex(
                      (item) => item.id === props.row.original.id
                    );
                    newState[index].assigned = !newState[index].assigned;
                    return newState;
                  })
                }
              />
            </div>
          );
        }
      }
    ],
    [tableState]
  );

  const tableContent =
    !isLoading && tableState ? (
      <>
        <div className={styles.tableContainer}>
          <ScopeTable columns={columns} data={tableState} />
          <Button
            className={styles.submitButtonStyle}
            onClick={() => {
              setShowModal(true);
            }}
          >
            Update
          </Button>
        </div>
        {renderModal(
          showModal,
          () => setShowModal(false),
          formatTableData(tableState),
          postScopeUpdates,
          currentProject?.projectId,
          currentProject?.name
        )}
      </>
    ) : null;

  const content = isAdmin ? (
    tableContent
  ) : (
    <div
      className={`${styles['page-centered']} ${styles['cfa-text-field-error']}`}
    >
      You are not authorized to view this page
    </div>
  );

  return <Template>{content}</Template>;
};

const ScopeTable = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  return (
    // apply the table props
    <table {...getTableProps()} className={styles.tableStyle}>
      <thead>
        {
          // Loop over the header rows
          headerGroups.map((headerGroup) => (
            // Apply the header row props
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                // Loop over the headers in each row
                headerGroup.headers.map((column) => (
                  // Apply the header cell props
                  <th
                    {...column.getHeaderProps()}
                    className={styles.tableHeader}
                  >
                    {
                      // Render the header
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      {/* Apply the table body props */}
      <tbody {...getTableBodyProps()}>
        {
          // Loop over the table rows
          rows.map((row) => {
            // Prepare the row for display
            prepareRow(row);
            return (
              // Apply the row props
              <tr {...row.getRowProps()}>
                {
                  // Loop over the rows cells
                  row.cells.map((cell) => {
                    // Apply the cell props
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{ paddingTop: '10px' }}
                      >
                        {
                          // Render the cell contents
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default ManageScopes;
