import { Fragment } from 'react';
import { useSelector } from 'react-redux';

const Profile = () => {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);

  const content = user
    ? JSON.stringify({ ...user, accessToken }, undefined, 2)
    : '';

  return (
    <Fragment>
      <h1>Profile</h1>
      <textarea readOnly cols={60} rows={30} value={content}></textarea>
    </Fragment>
  );
};

export default Profile;
