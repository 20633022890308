import { createApi } from '@reduxjs/toolkit/query/react';
import { vendorOnboardingBaseQuery } from '../helpers/apis';

export const onboardingApi = createApi({
  reducerPath: 'onboardingApi',
  baseQuery: vendorOnboardingBaseQuery,
  tagTypes: [
    'apis',
    'certifications',
    'projectInvitations',
    'vendorInvitations',
    'projects',
    'users'
  ],
  endpoints: () => ({})
});
