import { useState, useEffect } from 'react';
import { apiWithAuth } from '../../helpers/apis';
import { useParams } from 'react-router-dom';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import SwaggerUI from 'swagger-ui';
import 'swagger-ui/dist/swagger-ui.css';
import styles from './Swagger.module.scss';
import Template from '../Template/Template';

const Swagger = () => {
  const [swagger, setSwagger] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const { id } = useParams();

  useEffect(() => {
    async function getSwagger() {
      return await apiWithAuth.get(`apis/${id}/swagger`);
    }
    setErrorMessage(undefined);
    getSwagger()
      .then((response) => setSwagger(response.data))
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, [id]);

  useEffect(() => {
    SwaggerUI({
      domNode: document.getElementById('swaggerDiv'),
      spec: swagger
    });
  }, [swagger]);

  let content;

  if (errorMessage) {
    content = (
      <div
        className={`${styles['page-centered']} ${styles['cfa-text-field-error']}`}
      >{`An error occurred while retrieving the document: ${errorMessage}`}</div>
    );
  } else if (!swagger) {
    content = <CFASpinner />;
  } else {
    content = <div id="swaggerDiv" />;
  }
  return <Template>{content}</Template>;
};

export default Swagger;
