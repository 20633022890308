import { toast } from 'react-toastify';

const errorHandler = (data) => {
  const error = data.data;
  let message;
  if (error.response) {
    message = error.response.data.message;
  } else {
    message = error.message || 'The request failed';
  }

  return `An error occurred: ${message}`;
};

const toastPromise = (pending, success, error = errorHandler) => {
  return async (promise) => {
    return toast.promise(promise, {
      pending: {
        render(data) {
          return pending(data);
        }
      },
      success: {
        render(data) {
          return success(data);
        }
      },
      error: {
        render(data) {
          return error(data);
        }
      }
    });
  };
};

export default toastPromise;
