import styles from './Overview.module.scss';
import Template from '../../pages/Template/Template';
import { useSelector } from 'react-redux';
import cow from '../../assets/icons/parachuting-cow.png';

const Overview = () => {
  const { currentProject } = useSelector((state) => state.projects);
  const onboardingUser = useSelector((state) => state.onboardingUser);
  const vendorStatus =
    currentProject?.vendor?.status || onboardingUser?.vendor?.status;
  const getVendorStatusContent = () => {
    switch (vendorStatus) {
      case 'OVERVIEW':
        return (
          <p>
            Please read our overview document and begin to explore some of our
            APIs. If you'd like to move forward as a vendor, please submit our
            Vendor Certification form and we'll be in touch.
          </p>
        );
      case 'CERTIFICATION_REQUESTED':
        return (
          <p>
            We are reviewing your vendor certification and will be in contact
            shortly.
          </p>
        );
      case 'AWAITING_SIGNATURE':
        return (
          <p>
            You should have received a certification document via email. Please
            sign the document. When we have received and processed your
            document, you will become active in the portal and will have full
            access to our onboarding documentation.
          </p>
        );
      case 'ACTIVE':
        return (
          <p>
            Congratulations, you have been granted access for onboarding. Please
            use the links to the left to navigate the onboarding process.
          </p>
        );
      default:
        return (
          <p>
            Please use the links to the left to navigate the onboarding process.
          </p>
        );
    }
  };

  const vendorStatusContent = getVendorStatusContent();

  return (
    <Template>
      <div className={styles['home-content']}>
        <div className={styles['title-frame']}>
          <span className={styles['subtitle']}>WELCOME TO THE</span>
          <span className={styles['title']}>
            Chick-fil-A Vendor Onboarding Portal
          </span>
        </div>
        <div className={styles['vendor-status-label']}>
          {vendorStatusContent}
        </div>
        <div>
          <p className={styles['page-text']}>
            Supplier acknowledges and agrees that its access to the Vendor
            Bridge Technology does not constitute or imply endorsement of a
            vendor or its products or services by Chick-fil-A, Inc. Supplier's
            use of the Vendor Bridge Technology is therefore conditioned on
            Vendor's agreement to refrain from stating or implying in its
            marketing and promotional materials that Vendor enjoys any
            affiliation, endorsement or special status with Chick-fil-A, Inc. by
            virtue of being listed on Vendor Bridge, regardless of whether those
            materials are directed to Chick-fil-A Operators or Licensees.
          </p>
        </div>
        <div>
          <h4 className={styles['subheader']}>Overview</h4>
          <p className={styles['page-text']}>
            Vendor Bridge is a platform created by Chick-fil-A, Inc. that
            provides franchised Operators with the means to quickly, easily and
            securely share data between their chosen vendor and Chick-fil-A
            systems. The following are the key phases for integration with an
            overview of the steps to connect with our APIs.
          </p>
        </div>
        <div>
          <h4 className={styles['subheader']}>Business Requirements</h4>
          <ul className={styles['page-text']}>
            <li>
              The relationship between vendors and Vendor Bridge is owned and
              facilitated by the Operators that are the customers of those
              vendors.
            </li>
            <li>
              Access to Vendor Bridge is contingent on the vendor signing a
              Vendor Certification document and completing
            </li>
            <li>
              the required steps to connect with the Vendor Bridge platform.
              Once this process is complete, Operators can activate the vendor's
              connection through the Vendor Bridge Portal by opting-in and
              selecting the appropriate function and vendor.
            </li>
            <li>
              Chick-fil-A expects vendors to provide front-line support to
              Operators to address any issues with accessing or validating the
              data or functionality in their systems. Issues that are identified
              as Chick-fil-A Vendor Bridge, or API, issues (vs. vendor issues)
              should be escalated to the appropriate contact on the Chick-fil-A
              side. Vendors will provide an escalation path in the event we need
              to contact the vendor about an issue that is impacting Operator
              experience.
            </li>
            <li>
              Vendors will also be responsible for providing visibility into the
              timeline for connecting to Vendor Bridge and should notify
              Chick-fil-A of any delays or errors that could impact Operators.
            </li>
          </ul>
        </div>
        <div>
          <h4 className={styles['subheader']}>Development Requirements</h4>
          <ul className={styles['page-text']}>
            <li>
              To connect to Vendor Bridge on behalf of Operators, vendors must
              have a clear understanding of, and be able to implement
              three-legged-OAUTH. This is the first step in moving forward with
              development to use our available APIs. Additional details around
              connecting to Vendor Bridge on behalf of an Operator can be found
              on our Developer portal after access has been granted.
            </li>
            <li>
              Once you have access to our Developer Portal, your Project Space
              will guide you through the steps necessary to connect to our APIs
            </li>
            {/*<li>
            You can access API-specific documentation using the “API Catalog”
            menu at the top left of the Developer Portal. This will show you how
            the API functions and will let you test your system by calling our
            mock integration version of the API. (Comment out)
          </li>*/}
            <li>
              It is the Vendor’s responsibility to be aware of how each API
              functions, its proper use, and the timeliness of the API’s
              functionality.
            </li>
            <li>
              Based on a full understanding of how each Vendor Bridge API works,
              it is the Vendor’s responsibility to ensure that the vendor
              application functionality is driven appropriately from the use of
              the API (e.g., if you are using the Get Team Members API, an
              Operator’s Team Members will stop showing up in the API when they
              are terminated. The vendor system must handle this in a way that
              is most appropriate for its specific system).
            </li>
          </ul>
        </div>
        <div>
          <h4 className={styles['subheader']}>Go live Preparation</h4>
          <ul className={styles['page-text']}>
            <li>
              To move to get to production, the vendor needs to complete the
              following:
              <ul>
                <li>Complete end-to-end testing in our test environment</li>
                <li>
                  Provide Operator-facing link that will redirect to completing
                  integration within vendor system
                </li>
                <li>
                  Once tested in test environment, a button will appear for the
                  vendor to designate that they are ready to go live, this will
                  create a "coming soon" card in vendor bridge that can be
                  activated when the vendor is live.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div>
          <h4 className={styles['subheader']}>Release</h4>
          <ul className={styles['page-text']}>
            <li>
              The vendor should provide confirmation that its support team is
              knowledgeable about how to support Operators in the event issues
              arise on the vendor side of the connection. Including, but not
              limited to Tier 1 support contact details that can be shared with
              Operators. The vendor is responsible for continuing to provide
              visibility into any changes that could impact the Operator
              experience with Vendor Bridge and notifying the appropriate
              contact at Chick-fil-A.
            </li>
          </ul>
        </div>
      </div>
      <img
        src={cow}
        alt="parachuting cow"
        className={styles['parachuting-cow-1']}
      />
      <img
        src={cow}
        alt="parachuting cow"
        className={styles['parachuting-cow-2']}
      />
    </Template>
  );
};

export default Overview;
