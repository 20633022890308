import { useSelector } from 'react-redux';
import styles from './Oauth.module.scss';
import { Button } from 'cfa-react-components';
import { useHistory } from 'react-router-dom';
import Template from '../Template/Template';
import toastPromise from '../../helpers/toast-promise';
import { getProjectInfo } from '../../helpers/project-data';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import { usePatchProjectMutation } from '../../store/projectsApi';

const Oauth = () => {
  const [patchProject] = usePatchProjectMutation();

  const { currentProject } = useSelector((state) => state.projects);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const history = useHistory();

  const isLoading = !currentProject;

  const { environmentData, scopesList } = getProjectInfo(
    currentProject,
    'DEVELOPMENT'
  );

  const pendingHandler = () => 'Going to next step';
  const successHandler = (data) => {
    return 'Project moved to next step';
  };
  const requestAndNotify = toastPromise(pendingHandler, successHandler);

  const operatorUsername = environmentData?.testOperators[0]?.userName;
  const operatorPassword = environmentData?.testOperators[0]?.password;
  const operatorLocation = environmentData?.testOperators[0]?.locationNumber;
  const clientId = environmentData?.clientId;
  const clientSecret = environmentData?.secret;

  const missingProjectData = !(
    !!operatorUsername &&
    !!operatorPassword &&
    !!operatorLocation &&
    !!clientId &&
    !!clientSecret &&
    scopesList &&
    scopesList.length > 0
  );

  const proceedToNextStep = async () => {
    try {
      await requestAndNotify(
        patchProject({
          currentProjectId: currentProject.projectId,
          values: {
            projectStatus: 'STEP_2'
          }
        })
      );

      history.push('/development');
    } catch (error) {}
  };

  const continueToNextStep = async () => {
    history.push('/development');
  };

  const integrationUrl =
    currentProject?.scopes
      ?.map((scope) => scope.integrationUrl)
      .filter((url) => !!url)[0] || '';

  const ref_token_step1_curl = `curl -X POST \\
  -H "Accept: application/json" \\
  -H "Content-Type: application/json" \\
  -d '{
    "username": "${operatorUsername}",
    "password": "${operatorPassword}",
    "options": {
      "multiOptionalFactorEnroll": false,
      "warnBeforePasswordExpired": false
    }
  }' "https://cfahome.oktapreview.com/api/v1/authn"`;

  const ref_token_step2_curl = `curl -v -X GET \\
  "https://cfahome.oktapreview.com/oauth2/ausfyfbmuzNkrW2q00h7/v1/authorize?client_id=${clientId}&response_type=code&scope=offline_access${
    scopesList ? '%20' + scopesList?.toString().replaceAll(' ', '%20') : ''
  }&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Fcallback&state=1234&sessionToken=sessionTokenFromAuthnResponse"`;

  const ref_token_step3_auth_resp =
    '< location: http://localhost:8080/callback?code=AuthCodeFromAuthorizeResponse&state=1234';

  const ref_token_step3_curl = `curl -X POST \\
  -H "Accept: application/json" \\
  -H "Content-Type: application/x-www-form-urlencoded" \\
  -u ${clientId}:${clientSecret} \\
  "https://cfahome.oktapreview.com/oauth2/ausfyfbmuzNkrW2q00h7/v1/token?grant_type=authorization_code&redirect_uri=http://localhost:8080/callback&code=AuthCodeFromAuthorizeResponse"`;

  const call_api_step1_curl = `curl -X POST \\
  -H "Accept: application/json" \\
  -H "Content-Type: application/x-www-form-urlencoded" \\
  -u ${clientId}:${clientSecret} \\
  "https://cfahome.oktapreview.com/oauth2/ausfyfbmuzNkrW2q00h7/v1/token?grant_type=refresh_token&refresh_token=refreshTokenFromPreviousStep"`;

  // integration url value that's being added to the model should get used in this call
  const call_api_step2_curl = `curl -X GET -H "Authorization: Bearer accessTokenFromTokenResponse" \\
  "${integrationUrl}"`;

  let content;

  if (isLoading) {
    content = <CFASpinner />;
  } else if (missingProjectData && !isAdmin) {
    content = (
      <p>
        Your project is missing some required information. Please contact a CFA
        admin for help.
      </p>
    );
  } else {
    content = (
      <div className={styles['frame']}>
        <h2 className={styles['oauthHeader']}>1. OAuth in Action</h2>
        <div className={styles['oauthBody']}>
          <p>
            An understanding of Three Legged OAuth is needed to integrate with
            Chick-fil-A systems.
          </p>
          <p>
            The authorization code flow is used to grant third party systems
            access to back end systems on behalf of an authorized resource (in
            our case a CFA operator). For a better understanding of the
            authorization code flow, please read the following before continuing
            on to the curl sections below:{' '}
            <a
              target="_blank"
              href="https://developer.okta.com/docs/guides/implement-grant-type/authcode/main/#overview"
              rel="noreferrer"
            >
              Implement authorization by grant type | Okta Developer
            </a>
          </p>
          <p>
            The following curl should give you an idea of how the auth flow will
            work. The first section reviews being granted access to CFA systems
            on behalf of an operator by receiving a refresh token. The second
            section reviews using that refresh token to get an access token
            which can then be used to call Chick-fil-A apis.
          </p>
        </div>

        <h3 className={styles['oauthSubHeader']}>
          <b>Integration Auth Flow to get Refresh Token (via Curl)</b>
        </h3>
        <div className={styles['oauthStepParagraph']}>
          <p>
            You will not be responsible for implementing the logic for Steps 1
            and 2 below. Steps 1 and 2 are implemented by the Okta sign-in page
            that you will link to, but we are showing the curl here to help you
            understand the auth flow and what the Okta sign-in page is doing
            behind the scenes.
          </p>
          <p>Step 1: Get session token using operator creds</p>
        </div>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{ref_token_step1_curl}</pre>
          </div>
        </div>

        <p className={styles['oauthStepParagraph']}>
          Step 2: Pass session token from authn response to the authorize
          endpoint
        </p>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{ref_token_step2_curl}</pre>
          </div>
        </div>

        <div className={styles['oauthStepParagraph2']}>
          <p>
            Step 3: Get refresh token using auth code from authorize response
          </p>
          <p>
            You will implement this step in the callback endpoint that you will
            provide us the callback URL for. Okta will call your callback
            endpoint after an operator has entered credentials on the sign-in
            page and will pass an authorization code to you as a query
            parameter, however, when using curl, you'll need to manually
            retrieve the auth code from the code query parameter on the line
            below of the authorize response from the previous step:
          </p>
        </div>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{ref_token_step3_auth_resp}</pre>
          </div>
        </div>

        <p className={styles['oauthStepParagraph']}>
          Then send the auth code to the token endpoint to receive refresh and
          access tokens back
        </p>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{ref_token_step3_curl}</pre>
          </div>
        </div>

        <p className={styles['oauthStepParagraph3']}>
          The refresh token returned doesn’t expire as long as it is used once
          every 7 days and can be used to get access tokens for calling
          Chick-fil-A endpoints. Be sure to save this refresh token for future
          use.
        </p>
        <h3 className={styles['overviewSectionSubHeader']}>
          <b>Calling CFA APIs</b> <i>(via Curl)</i>
        </h3>
        <p className={styles['oauthStepParagraph']}>
          Step 1: Use refresh token you saved in the previous steps to get an
          access token
        </p>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{call_api_step1_curl}</pre>
          </div>
        </div>
        <p className={styles['oauthStepParagraph3']}>
          Step 2: Send the access token as a bearer token in the auth header to
          call any Chick-fil-A endpoint(s) you’ve been granted access to.
        </p>
        <div className={styles['codeBackground']}>
          <div className={styles['codeSnippet']}>
            <pre>{call_api_step2_curl}</pre>
          </div>
        </div>
        {currentProject?.projectStatus === 'STEP_1' && (
          <div>
            <p>
              Please click the button below to continue to the development step.
            </p>
            <Button
              className={styles['continueButton']}
              onClick={proceedToNextStep}
            >
              <span className={styles['overviewButtonText']}>
                NEXT&nbsp;STEP
              </span>
            </Button>
          </div>
        )}
        {currentProject?.projectStatus !== 'STEP_1' && (
          <div>
            <Button
              className={styles['continueButton']}
              onClick={continueToNextStep}
            >
              <span className={styles['overviewButtonText']}>CONTINUE</span>
            </Button>
          </div>
        )}
      </div>
    );
  }

  return <Template>{content}</Template>;
};

export default Oauth;
