import CertificationManager from '../CertificationManager';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import styles from '../InvitationModal/InvitationModal.module.scss';

const CertificationModal = ({
  showModal,
  handleClose,
  vendor,
  onboardingUser
}) => {
  return (
    <div className={styles['page-centered']}>
      <Dialog
        open={showModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogContent>
          <CertificationManager
            vendor={vendor}
            onboardingUser={onboardingUser}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CertificationModal;
