import { createSlice } from '@reduxjs/toolkit';
import { saveSelectedProject } from '../service/LocalStorageService';

const initialProjectsState = { projects: null, currentProject: null };

const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialProjectsState,
  reducers: {
    setProjects(state, action) {
      if (state.projects !== action.payload) {
        state.projects = action.payload;
      }
    },
    setCurrentProject(state, action) {
      if (
        JSON.stringify(state.currentProject) !== JSON.stringify(action.payload)
      ) {
        state.currentProject = action.payload;
        saveSelectedProject(state.currentProject);
      }
    },
    clearUserSession(state) {
      state = initialProjectsState;
    }
  }
});

export const projectsActions = projectsSlice.actions;

export default projectsSlice.reducer;
