import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = { accessToken: null, user: null, isAdmin: null };

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setAuthState(state, action) {
      if (state !== action.payload) {
        return action.payload;
      }
    },
    clearUserSession: () => initialAuthState
  }
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
