import Template from '../Template/Template';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ProjectUsers from './ProjectUsers';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import { Button, TextField } from 'cfa-react-components';
import styles from './ProjectAdmin.module.scss';
import InvitationModal from '../../components/Widget/InvitationModal/InvitationModal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  useGetVendorInvitationsQuery,
  useDeleteProjectInvitationMutation
} from '../../store/invitationsApi';
import { useUpdateProjectRedirectUrlMutation } from '../../store/projectsApi';
import toastPromise from '../../helpers/toast-promise';
import { useGetOnboardingUserQuery } from '../../store/usersApi';

const textFieldErrorStyling = styles['cfa-text-field-error'];

const pendingHandler = () => 'Saving redirect URL';
const successHandler = (data) => {
  return 'Redirect URL Saved';
};
const requestAndNotify = toastPromise(pendingHandler, successHandler);

const columns = [
  { id: 'email', label: 'Email', minWidth: 70, align: 'center' },
  { id: 'status', label: 'Status', minWidth: 70, align: 'center' },
  { id: 'delete', label: '', maxWidth: 20, align: 'left' }
];

const ProjectAdmin = () => {
  const [showModal, setShowModal] = useState(false);
  const { currentProject } = useSelector((state) => state.projects);
  const { isAdmin, user: oktaUser } = useSelector((state) => state.auth);
  const { data: onboardingUser } = useGetOnboardingUserQuery(oktaUser?.email);

  const [updateProjectRedirectUrl] = useUpdateProjectRedirectUrlMutation();

  const submitRedirectUrl = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await requestAndNotify(
        updateProjectRedirectUrl({
          currentProjectId: currentProject.projectId,
          values: values
        }).unwrap()
      );
    } catch (error) {}

    setSubmitting(false);
  };

  const userIsProjectAdmin =
    onboardingUser &&
    currentProject &&
    onboardingUser.email.toLowerCase() ===
      currentProject.primaryContactEmail.toLowerCase();

  const { data: invitations = [], isLoading } = useGetVendorInvitationsQuery(
    currentProject?.vendor?.vendorId,
    {
      refetchOnMountOrArgChange: true
    }
  );

  const [deleteProjectInvitation] = useDeleteProjectInvitationMutation();

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const componentIsFullyLoaded = !isLoading && currentProject;

  let content;

  const formik = useFormik({
    initialValues: {
      redirectUrl: ''
    },
    validationSchema: yup.object({
      redirectUrl: yup.string().url('Invalid URL').required('Required')
    }),
    onSubmit: submitRedirectUrl
  });

  if (!componentIsFullyLoaded) {
    content = <CFASpinner />;
  } else if (!isAdmin && !userIsProjectAdmin) {
    content = (
      <div
        className={`${styles['page-centered']} ${styles['cfa-text-field-error']}`}
      >
        You are not authorized to view this page
      </div>
    );
  } else {
    content = (
      <>
        <h2 className={`${styles['project-admin-header']}`}>Project Users</h2>
        <ProjectUsers
          rows={invitations}
          columns={columns}
          vendorId={currentProject?.vendor?.vendorId}
          projectId={currentProject?.projectId}
          deleteProjectInvitation={deleteProjectInvitation}
        />
        <InvitationModal
          showModal={showModal}
          handleClose={handleClose}
          projectInvite={{
            vendorId: currentProject.vendor.vendorId,
            projectId: currentProject.projectId
          }}
        />
        <div className={`${styles['centered-container']}`}>
          <Button color="primary" onClick={handleShow}>
            Invite User
          </Button>
        </div>
        <br></br>
        <hr></hr>
        <br></br>
        <h2 className={`${styles['project-admin-header']}`}>Redirect URL</h2>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles['redirect-container']}>
            <TextField
              required
              false
              id="redirectUrl"
              name="redirectUrl"
              label="Additional Redirect URL"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.redirectUrl}
              placeholder="https://..."
            />
            {formik.errors.redirectUrl && formik.touched.redirectUrl ? (
              <div className={textFieldErrorStyling}>
                {formik.errors.redirectUrl}
              </div>
            ) : null}
          </div>

          <div className={styles['centered-container']}>
            <Button
              style={{ width: '220px' }}
              color="primary"
              type="submit"
              disabled={
                !(formik.dirty && formik.isValid) || formik.isSubmitting
              }
            >
              Submit Redirect URL
            </Button>
          </div>
        </form>
      </>
    );
  }

  return <Template>{content}</Template>;
};

export default ProjectAdmin;
