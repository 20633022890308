import Template from '../Template/Template';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import styles from './PendingCertifications.module.scss';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Link from '@mui/material/Link';
import CertificationModal from '../../components/Widget/CertificationModal/CertificationModal';
import { useState } from 'react';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import { useGetOnboardingUserQuery } from '../../store/usersApi';
import { useGetCertificationsQuery } from '../../store/certificationsApi';

const PendingCertifications = () => {
  const { user } = useSelector((state) => state.auth);

  const { data: onboardingUser } = useGetOnboardingUserQuery(user?.email);

  const { data: certifications = [] } = useGetCertificationsQuery();

  const [showModal, setShowModal] = useState(false);
  const [vendor, setVendor] = useState(null);

  const handleClose = () => setShowModal(false);
  const handleShow = (vendor) => {
    setVendor(vendor);
    setShowModal(true);
  };

  const isLoading = !certifications;

  const getPendingCertifications = () => {
    if (certifications) {
      return certifications.filter((certification) => {
        return (
          certification.vendor.status === 'CERTIFICATION_REQUESTED' ||
          certification.vendor.status === 'AWAITING_SIGNATURE'
        );
      });
    } else {
      return null;
    }
  };

  const pendingCertifications = getPendingCertifications();
  return isLoading ? (
    <CFASpinner />
  ) : (
    <Template>
      <TableContainer>
        <Table
          sx={{ minWidth: 650 }}
          style={{ width: 1200, margin: 'auto' }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={styles.tableHeader}>Project</TableCell>
              <TableCell className={styles.tableHeader}>Description</TableCell>
              <TableCell className={styles.tableHeader}>Vendor</TableCell>
              <TableCell className={styles.tableHeader}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pendingCertifications?.map((certification) => (
              <TableRow
                key={certification.certificationId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>
                  <Link
                    href="#"
                    underline="none"
                    onClick={() => handleShow(certification.vendor)}
                  >
                    {certification.projectName}
                  </Link>
                </TableCell>
                <TableCell>{certification.projectDescription}</TableCell>
                <TableCell component="th" scope="row">
                  {certification.vendor.name}
                </TableCell>
                <TableCell>{certification.vendor.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CertificationModal
        showModal={showModal}
        handleClose={handleClose}
        vendor={vendor}
        onboardingUser={onboardingUser}
      />
    </Template>
  );
};

export default PendingCertifications;
