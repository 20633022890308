import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const DeleteConfirmation = ({ showModal, handleClose, handleConfirm }) => {
  return (
    <div>
      <Dialog open={showModal} onClose={handleClose}>
        <DialogTitle>Confirm Show Secret</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The user will only be able to see the secret once and if they leave
            the page, they won’t be able to see the secret again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Go Back</Button>
          <Button onClick={handleConfirm} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteConfirmation;
