import { onboardingApi } from './onboardingApi';

const apisApi = onboardingApi.injectEndpoints({
  endpoints: (build) => ({
    getApis: build.query({
      query: () => {
        return {
          url: `apis`,
          method: 'get'
        };
      },
      providesTags: ['apis']
    }),
    getSwagger: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `apis/${id}/swagger`,
          method: 'get'
        };
      },
      keepUnusedDataFor: 1800
    }),
    getDocumentation: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `projects/documentation/${id}`,
          method: 'get'
        };
      },
      keepUnusedDataFor: 1800
    })
  })
});

export const { useGetApisQuery, useGetSwaggerQuery, useGetDocumentationQuery } =
  apisApi;
