import { Redirect } from 'react-router-dom';
import OktaSignInWidget from '../../components/Auth/OktaSignInWidget';
import { useOktaAuth } from '@okta/okta-react';

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in', err);
  };
  if (!authState) return null;

  if (authState.isAuthenticated) {
    return <Redirect to={{ pathname: '/' }} />;
  } else {
    return (
      <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={onError}
      />
    );
  }
};
export default Login;
