import { useSelector } from 'react-redux';
import Template from '../Template/Template';
import { useEffect, useState } from 'react';
import CertificationManager from '../../components/Widget/CertificationManager';
import styles from './Certification.module.scss';
import medal from '../../assets/icons/medal.svg';
import { useGetOnboardingUserQuery } from '../../store/usersApi';

const Certification = () => {
  const [vendor, setVendor] = useState(null);
  const [project, setProject] = useState(null);
  const { currentProject } = useSelector((state) => state.projects);

  const { user } = useSelector((state) => state.auth);

  const { data: onboardingUser } = useGetOnboardingUserQuery(user?.email);

  useEffect(() => {
    if (currentProject) {
      setProject(currentProject);
      setVendor(currentProject.vendor);
    } else if (onboardingUser) {
      setProject(null);
      setVendor(onboardingUser.vendor);
    }
  }, [currentProject, onboardingUser]);

  return (
    <Template>
      <div className={styles['certification-content']}>
        <CertificationManager
          vendor={vendor}
          project={project}
          onboardingUser={onboardingUser}
        />
      </div>
      <img src={medal} className={styles['medal']} alt="medal" />
    </Template>
  );
};

export default Certification;
