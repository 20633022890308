import logo from '../assets/icons/cfa-okta-logo.svg';
import ReactDOM from 'react-dom';
import ResetPasswordModal from '../components/Widget/ResetPasswordModal/ResetPasswordModal';

const oktaAuthConfig = {
  issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER_ID}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  pkce: true
};

const oktaSignInConfig = {
  baseUrl: `https://${process.env.REACT_APP_OKTA_DOMAIN}`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
  logo: logo,
  authParams: {
    issuer: `https://${process.env.REACT_APP_OKTA_DOMAIN}/oauth2/${process.env.REACT_APP_OKTA_AUTH_SERVER_ID}`,
    pkce: true,
    responseType: ['code', 'token', 'id_token'],
    scopes: ['openid', 'email', 'profile']
  },
  customButtons: [
    {
      title: 'Reset Password',
      className: 'btn-customAuth',
      click: () => {
        const modalDiv = document.getElementById('modalDiv');
        ReactDOM.unmountComponentAtNode(modalDiv);
        ReactDOM.render(<ResetPasswordModal />, modalDiv);
      }
    }
  ]
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

export { oktaAuthConfig, oktaSignInConfig };
