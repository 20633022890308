import Paper from '@mui/material/Paper';
import Template from '../Template/Template';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { useState } from 'react';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import styles from './ListInvites.module.scss';
import { useGetAllVendorInvitationsQuery } from '../../store/invitationsApi';

const ListInvites = () => {
  const { data: allInvitations = [], isLoading } =
    useGetAllVendorInvitationsQuery({
      refetchOnMountOrArgChange: true
    });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const componentIsFullyLoaded = !isLoading;

  const columns = [
    { id: 'email', label: 'Email', minWidth: 70, align: 'left' },
    { id: 'status', label: 'Status', minWidth: 70, align: 'left' },
    { id: 'vendor', label: 'Vendor Name', maxWidth: 70, align: 'left' }
  ];

  let content;

  if (!componentIsFullyLoaded) {
    content = <CFASpinner />;
  } else {
    content = (
      <Paper
        sx={{ width: '90%', overflow: 'hidden', margin: 'auto' }}
        variant="outlined"
      >
        <>
          <h2 className={`${styles['all-vendor-invites-header']}`}>
            All User Invites
          </h2>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, color: '#5b6770' }}
                    >
                      <h3>{column.label}</h3>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allInvitations
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((invitation) => (
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>{invitation.email}</TableCell>
                      <TableCell>{invitation.status}</TableCell>
                      <TableCell>{invitation.vendorName}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ display: 'flex', justifyContent: 'center' }}
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={allInvitations?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      </Paper>
    );
  }
  return <Template>{content}</Template>;
};

export default ListInvites;
