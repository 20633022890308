import axios from 'axios';

const getAccessTokenFromLocalStorage = () => {
  const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
  return tokenStorage?.accessToken?.accessToken;
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI
});

export const apiWithAuth = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URI,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-type': 'Application/json'
  }
});

apiWithAuth.interceptors.request.use((config) => {
  config.headers[
    'Authorization'
  ] = `Bearer ${getAccessTokenFromLocalStorage()}`;
  return config;
});

export const vendorOnboardingBaseQuery = async ({
  url,
  method,
  data,
  params
}) => {
  try {
    const result = await apiWithAuth({ url, method, data, params });
    return { data: result.data };
  } catch (axiosError) {
    let err = axiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    };
  }
};
