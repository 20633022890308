import { Dropdown } from 'cfa-react-components';

const ScopeSelector = (props) => {
  const onChange = (event) => {
    props.handleChange(props.index, event);
  };

  if (props.api != null && props.api !== '') {
    return (
      <Dropdown
        id="dropdown"
        label="Scope"
        options={props.api['scopes']}
        value={props.value}
        onChange={onChange}
        renderOption={(scope) => scope.scopeName}
        getOptionId={(scope) => scope.scopeId}
        getOptionText={(scope) => scope.scopeName}
        size="small"
        style={{ fontSize: '1rem' }}
      />
    );
  } else {
    const dummyData = {
      api: { scopes: [{}] },
      value: { scopeId: '', scopeName: '' }
    };
    return (
      <Dropdown
        disabled={true}
        id="dropdown"
        label="Scope"
        options={dummyData.api['scopes']}
        value={dummyData.value}
        onChange={onChange}
        renderOption={(scope) => scope.scopeName}
        getOptionId={(scope) => scope.scopeId}
        getOptionText={(scope) => scope.scopeName}
        size="small"
        style={{ fontSize: '1rem' }}
      />
    );
  }
};

export default ScopeSelector;
