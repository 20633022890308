import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import { Accordion, AccordionItem } from 'cfa-react-components';
import { getApiCatalogData } from '../../helpers/api-catalog-data';
import Template from '../Template/Template';
import apiCatalogCss from './ApiCatalog.module.scss';

const columnHeadingStyle = {
  fontFamily: 'Apercu',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',
  textTransform: 'uppercase',
  color: '#5B6770',
  minWidth: '250px'
};

const ApiCatalog = () => {
  const {
    teamMemberEndpointData,
    schedulesEndpointData,
    retirement401kEndpointData
  } = getApiCatalogData();

  const examplePayload = `
${''}  
{
    "url": https: //my.host.com/schedule/events,
    "httpMethod": "POST",
    "authentication": {
        "basic": {
            "username": "username",
            "password": "password"
        }
    }
}`;

  const example401kPayload = `
${''}  
{
    "effectiveDate": "2024-03-07",
    "endDate": "2024-03-07",
    "operator": {
      "adId": "string",
      "name": "string",
      "operatedBy": "OPR",
      "operatorNumber": "string",
      "personId": "string"
    },
    "startDate": "2024-03-07",
    "teamMembers": [
      {
        "demographicData": {
          "address": [
            {
              "city": "string",
              "country": "USA",
              "county": "string",
              "postalCode": "string",
              "state": "st",
              "streetLines": [
                "string"
              ]
            }
          ],
          "contactMediums": [
            {
              "country": "USA",
              "type": "HOME_PHONE",
              "value": "string"
            }
          ],
          "dateOfBirth": "2024-03-07",
          "latestHireDate": "2024-03-07",
          "originalHireDate": "2024-03-07"
        },
        "firstName": "string",
        "lastName": "string",
        "middleName": "string",
        "payrollData": {
          "401kAfterTax": 0,
          "401kCatchupPreTax": 0,
          "401kCatchupRoth": 0,
          "401kEmployer": 0,
          "401kLoan": 0,
          "401kPreTax": 0,
          "401kRoth": 0,
          "estimatedAnnualEarnings": 0,
          "grossEarnings": 0,
          "hoursWorked": 0,
          "rothIRA": 0,
          "rothIRACatchup": 0,
          "simpleIRA": 0,
          "simpleIRACatchup": 0,
          "simpleIRAEmployer": 0,
          "simpleIRARoth": 0,
          "traditionalIRA": 0,
          "traditionalIRACatchup": 0
        },
        "socialSecurityNumber": "string"
      }
    ]
}`;

  const exampleEvent = `
${''}
{
    "globalPersonId": "2986348",
    "shiftDate": "2022-10-19",
    "locationNumber": "30003",
    "shiftIds": [
        "b85d2e51-b8ea-4c2f-af32-d6ab606eb3df"
    ],
    "eventType": "CREATE"
}
`;

  const accordionItems = [
    <AccordionItem
      key="panel-1"
      itemKey="panel-1"
      id="panel-1"
      header="Team Member Api"
    >
      <div className={apiCatalogCss['catalog-item-text']}>
        <p>
          Team Member API is a RESTFul service providing information about
          Chick-fil-A team members. Team member info can be retrieved by
          location or by global person ID, and new team members can be onboarded
          into Chick-fil-A’s payroll systems via the API.
        </p>

        <p>The following endpoints are exposed to vendors by the API.</p>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={columnHeadingStyle}>Endpoint</TableCell>
              <TableCell sx={columnHeadingStyle} align="center">
                Scope(s)
              </TableCell>
              <TableCell sx={columnHeadingStyle} align="right">
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamMemberEndpointData.map((row) => (
              <TableRow key={row.endpoint.route + row.endpoint.type}>
                <TableCell>
                  {row.endpoint.type} <code>{row.endpoint.route}</code>
                </TableCell>
                <TableCell align="left">{row.scopes}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </AccordionItem>,

    <AccordionItem
      key="panel-2"
      itemKey="panel-2"
      id="panel-2"
      header="Schedule Api"
    >
      <div className={apiCatalogCss['catalog-item-text']}>
        <p>
          Schedule API is a RESTFul service providing schedule information for
          Chick-fil-A team members. Shifts can be retrieved by location or by
          shift ID, and new shifts can be pushed via the API. An integration is
          also in place that populates the API with schedules for any locations
          using HotSchedules.
        </p>

        <p>The following endpoints are exposed to vendors by the API.</p>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={columnHeadingStyle}>Endpoint</TableCell>
              <TableCell sx={columnHeadingStyle} align="center">
                Scope(s)
              </TableCell>
              <TableCell sx={columnHeadingStyle} align="right">
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {schedulesEndpointData.map((row) => (
              <TableRow>
                <TableCell>
                  {row.endpoint.type} <code>{row.endpoint.route}</code>
                </TableCell>
                <TableCell align="left">{row.scopes}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className={apiCatalogCss['catalog-item-text']}>
        <p>
          {`To receive schedule events, set up a subscription with POST
          "/locations/{location}/event-subscriptions/shifts" and provide the
          url/authentication/etc. for our servers to be notified at. See below
          for an example.`}
        </p>

        <p>{`Example payload:`}</p>

        <div className={apiCatalogCss.codeBackground}>
          <div className={apiCatalogCss.codeSnippet}>
            <pre>{examplePayload}</pre>
          </div>
        </div>

        <p>
          {`After creating the subscription, you can send a test message with POST /locations/{location}/event-subscriptions/shifts/sendTestMessage`}
        </p>

        <p>
          {`Any time a shift is created, updated, or deleted at that location, the endpoint you provided will be called with a request body that includes meta data about the change, the global person id, shift date, location number, shift ids, and event type.`}
        </p>

        <p>{` Example event:`}</p>

        <div className={apiCatalogCss.codeBackground}>
          <div className={apiCatalogCss.codeSnippet}>
            <pre>{exampleEvent}</pre>
          </div>
        </div>

        <p>
          {`For create and update events, you may use the values in the event message to retrieve the shift using the get shift by id endpoint GET /locations/{location}/shifts/{shiftId}. For delete events, the shift will no longer exist.`}
        </p>
      </div>
    </AccordionItem>,

    <AccordionItem
      key="panel-3"
      itemKey="panel-3"
      id="panel-3"
      header="401k Api"
    >
      <div className={apiCatalogCss['catalog-item-text']}>
        <p>
          Team Member 401k is a RESTful service providing Operator selected
          vendors with information about their Restaurant Team Members 401k
          information. 401k information can be retrieved using the Operator
          number.
        </p>

        <p>The following endpoints are exposed to vendors by the API.</p>
      </div>

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={columnHeadingStyle}>Endpoint</TableCell>
              <TableCell sx={columnHeadingStyle} align="center">
                Scope(s)
              </TableCell>
              <TableCell sx={columnHeadingStyle} align="right">
                Description
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {retirement401kEndpointData.map((row) => (
              <TableRow>
                <TableCell>
                  {row.endpoint.type} <code>{row.endpoint.route}</code>
                </TableCell>
                <TableCell align="left">{row.scopes}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={3}></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className={apiCatalogCss['catalog-item-text']}>
        <p>{`Example payload:`}</p>

        <div className={apiCatalogCss.codeBackground}>
          <div className={apiCatalogCss.codeSnippet}>
            <pre>{example401kPayload}</pre>
          </div>
        </div>
      </div>
    </AccordionItem>
  ];

  return (
    <Template>
      <div className={apiCatalogCss['api-catalog-content']}>
        <div className={apiCatalogCss['headline']}>
          <div className={apiCatalogCss['header']}>API Catalog</div>
        </div>

        <Accordion
          defaultActiveItemKey={['panel-1', 'panel-2', 'panel-3']}
          enableMultipleOpen
        >
          <div className={apiCatalogCss['catalog-items']}>{accordionItems}</div>
        </Accordion>
      </div>
    </Template>
  );
};

export default ApiCatalog;
