import { useSelector } from 'react-redux';
import styles from './Development.module.scss';
import { apiWithAuth } from '../../helpers/apis';
import { Button } from 'cfa-react-components';
import Template from '../Template/Template';
import toastPromise from '../../helpers/toast-promise';
import { getProjectInfo } from '../../helpers/project-data';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import ShowSecretConfirmation from '../../components/Widget/ShowSecretConfirmation';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  usePatchProjectMutation,
  useGetProjectDataQuery,
  usePatchProjectDataMutation
} from '../../store/projectsApi';

const Development = () => {
  const { currentProject } = useSelector((state) => state.projects);

  const [allowedApisAndScopes, setAllowedApisAndScopes] = useState();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const history = useHistory();

  const isLoading = !currentProject;

  const { environmentData, scopesList } = getProjectInfo(
    currentProject,
    'DEVELOPMENT'
  );

  const getSampleAppUrl = `${apiWithAuth.defaults.baseURL}/assets/sample-application.zip`;

  const [patchProject] = usePatchProjectMutation();
  const [patchProjectData] = usePatchProjectDataMutation();

  const projectDataPayload = {
    projectId: currentProject?.projectId,
    projectDataId: currentProject?.data.find(
      (pd) => pd.environment === 'DEVELOPMENT'
    ).projectDataId
  };
  var { data: projectData } = useGetProjectDataQuery(projectDataPayload);

  useEffect(() => {
    if (currentProject) {
      var show = false;

      if (
        projectData &&
        projectData.showSecret &&
        projectData.environment === 'DEVELOPMENT'
      ) {
        show = projectData.showSecret;
        return false;
      }

      if (!show || isAdmin) {
        document.getElementById('secretButton').remove();
      }

      const tempMap = new Map();
      currentProject.scopes?.forEach((scope) => {
        const id = scope.api.apiId;
        if (tempMap.has(id)) {
          const api = tempMap.get(id);
          api.scopes.push(scope.scopeName);
        } else {
          tempMap.set(id, {
            id,
            name: scope.api.name,
            scopes: [scope.scopeName],
            documents: [...scope.api.apiDocuments]
          });
        }
      });
      setAllowedApisAndScopes(Array.from(tempMap.values()));
    }
  }, [currentProject]);

  const pendingHandler = () => 'Going to next step';

  const successHandler = (data) => {
    return 'Project moved to next step';
  };

  const successShowHandler = () => 'Secret displayed';

  const requestAndNotify = toastPromise(pendingHandler, successHandler);
  const requestAndNotifyShow = toastPromise(pendingHandler, successShowHandler);

  const operatorUsername = environmentData?.testOperators[0]?.userName;
  const operatorPassword = environmentData?.testOperators[0]?.password;
  const operatorLocation = environmentData?.testOperators[0]?.locationNumber;
  const operatorNumber = environmentData?.testOperators[0]?.operatorNumber;

  const clientId = environmentData?.clientId;
  const clientSecret = environmentData?.secret;

  const missingProjectData = !(
    !!operatorUsername &&
    !!operatorPassword &&
    !!operatorLocation &&
    !!clientId &&
    !!clientSecret &&
    scopesList &&
    scopesList.length > 0
  );

  const integrationUrl =
    currentProject?.scopes
      ?.map((scope) => scope.integrationUrl)
      .filter((url) => !!url)[0] || '';

  const invalidProjectStatus = !(
    currentProject?.projectStatus && currentProject?.projectStatus !== 'STEP_1'
  );

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleConfirm = () => {
    setShowModal(false);
    showSecret();
  };

  const showSecret = () => {
    try {
      if (projectData) {
        projectData = {
          ...projectData,
          showSecret: false
        };
        patchProjectDataFromShow();
        document.getElementById('secret').innerHTML = projectData.secret;
        document.getElementById('secretButton').remove();
      }
    } catch (error) {}
  };

  const patchProjectDataFromShow = async () => {
    try {
      await requestAndNotifyShow(
        patchProjectData({
          currentProjectId: projectData.project.projectId,
          projectDataId: projectData.projectDataId,
          values: {
            showSecret: projectData.showSecret
          }
        }).unwrap()
      );
    } catch (error) {}
  };

  const proceedToNextStep = async () => {
    try {
      await requestAndNotify(
        patchProject({
          currentProjectId: currentProject.projectId,
          values: {
            projectStatus: 'STEP_3'
          }
        }).unwrap()
      );
      history.push('/nonprod');
    } catch (error) {}
  };

  const continueToNextStep = async () => {
    history.push('/nonprod');
  };

  const getDocumentsContent = (api) => {
    let contentLinks = [];
    api.documents?.forEach((doc) => {
      contentLinks.push(
        <>
          <Link
            className={styles['scopes-display-text']}
            to={`documentation/${doc.documentId}`}
          >
            {doc.name}
          </Link>
          <br />
        </>
      );
    });
    return <div>{contentLinks}</div>;
  };

  let content;

  if (isLoading) {
    content = <CFASpinner />;
  } else if (invalidProjectStatus && !isAdmin) {
    content = <p>Access is denied to this page.</p>;
  } else if (missingProjectData && !isAdmin) {
    content = (
      <p>
        Your project is missing some required information. Please contact a CFA
        admin for help.
      </p>
    );
  } else {
    content = (
      <div className={styles['frame']}>
        <h2 className={styles['title']}>2. App Development</h2>
        <div className={styles['first-text-frame']}>
          <p>
            Now that you understand the OAuth2 authentication flow, you can
            begin development. You’ll be responsible for developing two
            applications:
          </p>
          <ol>
            <li>
              <span className={styles.highlight}>Authentication Flow App:</span>{' '}
              This is a webpage that will enable operators to follow an
              authentication flow to sign up for your services. It will be used
              to enable Operators (restaurant owners) to delegate access to
              yourselves (software vendors) to use Chick-fil-A APIs on their
              behalf to access their data based on the steps outlined below:
              <ol>
                <li>
                  In production, the operator will need to enable data sharing
                  with your application prior to logging into your login app,
                  and they will do this through an internal CFA application
                  called Vendor Bridge. After connecting through Vendor Bridge
                  the operator will be redirected to your login app to login and
                  give you the Okta refresh token. If the operator skips the
                  connection through vendor bridge, they will get an error
                  stating “User is not assigned to client“ when trying to grant
                  you the refresh token.
                </li>
                <li>
                  Your webpage will redirect the Operator to login to Okta then
                  Okta will redirect back to your webpage to return Okta OAuth
                  information, which you will capture and associate with the
                  Operator in a database.
                </li>
                <li>
                  You will host this webpage in both non-prod and prod
                  environments, and it will accept traffic from Chick-fil-A’s
                  website.
                </li>
              </ol>
            </li>
            <li>
              <span className={styles.highlight}>Vendor App:</span> This is the
              app you will write to invoke Chick-fil-A apis to provide a service
              to operators based on the following:
              <ol>
                <li>
                  You will need to create fresh access tokens to access
                  Chick-fil-A apis for each Operator.
                </li>
                <li>
                  To get fresh access tokens, you will invoke the Okta token
                  exchange api to convert your Okta client id & secret, along
                  with your Operator's refresh token into an access token.
                </li>
                <li>
                  This access token can then be included in the http header when
                  you make calls to Chick-fil-A apis.
                </li>
                <li>
                  You will host this application in both non-prod and prod
                  environments.
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <p>You’ve been granted access to the following api(s) and scope(s):</p>
        <div>
          <div className={styles['scopes-display']}>
            <p className={styles['scopes-display-text']}>API NAME</p>
            <p className={styles['scopes-display-text']}>SCOPE NAME</p>
            <p className={styles['scopes-display-text']}>DOCUMENTATION</p>
          </div>
          {allowedApisAndScopes?.map((api, index) => (
            <div key={index} className={styles['scopes-display']}>
              <p className={styles['scopes-display-text']}>{api.name}</p>
              <p className={styles['scopes-display-text']}>
                {api.scopes.map((scope) => (
                  <>
                    {scope}
                    <br />
                  </>
                ))}
              </p>
              <p className={styles['scopes-display-text']}>
                <Link
                  className={styles['scopes-display-text']}
                  to={`swagger-ui/${api.id}`}
                >
                  OpenAPI 3
                </Link>
                {getDocumentsContent(api)}
              </p>
            </div>
          ))}
        </div>
        <p>
          Please be sure to review the swagger using the link above to
          familiarize yourself with the api(s). The credentials we are providing
          you have access to hit apis hosted in CFA’s integration environment.
          All data returned from integration environment apis is mocked.
        </p>
        <h2 className={styles['title']}>Sample Java Application</h2>
        <p>
          To facilitate your development, we’re providing a sample Java
          application to demo basic functionality needed to develop both of your
          applications. The sample app demonstrates how to accept operator
          credentials then use them to generate a refresh token (needed for your
          auth flow app). It will also show how to exchange a refresh token for
          an access token then use it to call a CFA api (needed for your vendor
          app). You’ll see example requests and responses as well as code that
          can be used to implement your apps.
        </p>
        <p>
          Before beginning your development, we highly recommend you download
          and run the sample application by following these steps:
        </p>
        <code>
          <ol>
            <li>
              Download the app from{' '}
              <a
                href={getSampleAppUrl}
                style={{ textDecoration: 'none' }}
                download
              >
                here
              </a>
            </li>
            <li>Extract zip into a new project folder</li>
            <li>
              Copy and paste the application.properties settings from below into
              the ‘src/main/resources/application.properties’ file within your
              project.
            </li>
            <li>Run the project locally on your computer</li>
            <li>
              Open your browser to{' '}
              <a
                href="http://localhost:8080"
                style={{ textDecoration: 'none' }}
                target="true"
              >
                http://localhost:8080
              </a>
            </li>
            <li>
              Click on the ‘here’ link at the bottom of the first page to
              initiate the Three Legged OAuth Flow
            </li>
            <li>
              Login with operator{' - '}
              <span className={styles.highlight}>{operatorNumber}</span>
              <br></br>
              username:{' '}
              <span className={styles.highlight}>{operatorUsername}</span>
              <br></br>
              password:
              <span className={styles.highlight}> {operatorPassword}</span>
            </li>
            <li>Navigate through the remaining screens</li>
          </ol>
        </code>

        <p>
          Copy and paste the settings below into the application.properties file
          of your sample app:
        </p>

        <code>
          # The OKTA Authorization Server configured to handle Third Party
          Clients
          <br />
          auth.server=
          <span className={styles.highlight}>ausfyfbmuzNkrW2q00h7</span>
          <br />
          # Client ID and Secret are provided for each Third Party Client
          <br />#{' '}
          <strong>
            Secret will only be displayed once, please ensure you save it for
            your records
          </strong>
          <br />
          client.id=<span className={styles.highlight}>{clientId}</span>
          <br />
          client.secret=
          <span id="secret" className={styles.highlight}>
            {clientSecret}
          </span>{' '}
          <Button id="secretButton" color="primary" onClick={handleShow}>
            Show Secret
          </Button>
          <br />
          # The URL to the CFA Login Page
          <br />
          login.url=
          <span className={styles.highlight}>
            https://custom-okta-login.vendor-bridge.api-dev.cfadevelop.com/login
          </span>
          <br />
          # The URL used to exchange the authorization code for Access/Refresh
          tokens
          <br />
          token.url=
          <span className={styles.highlight}>
            https://cfahome.oktapreview.com/oauth2/%s/v1/token?grant_type=authorization_code&redirect_uri=%s&code=%s
          </span>
          <br />
          # The URL used to request a new Access Token based on Client
          Credentials, Scope and Refresh Token
          <br />
          refresh.token.url=
          <span className={styles.highlight}>
            https://cfahome.oktapreview.com/oauth2/%s/v1/token?grant_type=refresh_token&refresh_token=%s
          </span>
          <br />
          # The URL that OKTA will redirect back to with the code after
          successful authentication
          <br />
          redirect.url=
          <span className={styles.highlight}>
            http://localhost:8080/callback
          </span>
          <br />
          # scopes requested in the resulting Access Token
          <br />
          scopes=
          <span className={styles.highlight}>offline_access {scopesList}</span>
          <br />
          # The URL to the CFA endpoint that will be called
          <br />
          api.url=
          <span className={styles.highlight}>{integrationUrl}</span>
          <br />
        </code>
        <h2 className={styles['title']}>Authentication Flow App Development</h2>
        <p>
          Now you’re ready to begin development of your auth flow application.
        </p>
        <p>
          Your authentication flow app should call the CFA Okta login url (
          <span className={styles.highlight}>
            https://cfa-custom-okta-login.apimgmt-dev.cfadevelop.com/login
          </span>
          ) and pass query parameters for scope, client_id, and redirect_uri to
          render the Okta login widget. Your values for each of these along with
          all the property values you’ll need for local development are
          displayed in the application.properties example above. Redirect_uri is
          the URL Okta will call to send the OAuth activation code back to your
          app after an operator has entered their credentials. This should be an
          endpoint that accepts the activation code and exchanges it for a
          refresh token by calling the Okta token endpoint (see
          CallbackController in the sample app and/or step 3 from the OAuth in
          Action auth flow steps for examples). When the refresh token is
          returned, be sure to save it in your database and associate it with
          the operator whose credentials were entered.
        </p>
        <p>A few things to consider while developing:</p>
        <ul>
          <li>
            Plan for calling a different CFA Okta login url for your production
            deployment of the app.
          </li>
          <li>
            All of the application.properties values above will have different
            values for your prod deployment. Your non-prod deployment will only
            require updates to the redirect.url.
          </li>
          <li>
            You will need to store your refresh token securely for use by your
            vendor application to generate access tokens. You are running this
            with a single test operator in development, however keep in mind in
            prod many operators will use this webpage and you will need to store
            a different refresh token for each operator. An operator may or may
            not have multiple stores and a single refresh token should be used
            by your app for all stores that belong to an operator.
          </li>
        </ul>
        <p>
          After you’ve completed local development and testing of your
          authentication flow app and are successfully storing refresh tokens by
          operator then you should be able to proceed with development of your
          vendor app.
        </p>
        <h2 className={styles['title']}>Vendor App</h2>
        <p>
          Your main vendor application is the one that will provide value to
          Operators by invoking Chick-fil-A apis. Your vendor app will use the
          refresh token(s) stored by your auth flow app and exchange them for
          access tokens to use for calling endpoints.
        </p>
        <p>
          When you’ve completed local development and are able to call
          team-member-api endpoints successfully for location{' '}
          <span className={styles.highlight}>{operatorLocation} </span>from your
          app then you are ready to deploy to a hosted non-prod environment.
        </p>
        <p>Please click the button below to continue to the non-prod step.</p>
        {currentProject?.projectStatus === 'STEP_2' && (
          <Button color="primary" onClick={proceedToNextStep}>
            PROCEED TO NON-PROD STEP
          </Button>
        )}
        {currentProject?.projectStatus !== 'STEP_2' && (
          <Button color="primary" onClick={continueToNextStep}>
            CONTINUE
          </Button>
        )}
        <ShowSecretConfirmation
          showModal={showModal}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      </div>
    );
  }

  return <Template>{content}</Template>;
};

export default Development;
