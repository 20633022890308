import { useDispatch, useSelector } from 'react-redux';
import { projectsActions } from '../../store/projects';
import { Dropdown } from 'cfa-react-components';
import useAuthentication from '../../hooks/use-authentication';
import { useGetProjectsQuery } from '../../store/projectsApi';

const ProjectSelector = () => {
  const dispatch = useDispatch();
  const { data: projects } = useGetProjectsQuery();
  const { currentProject } = useSelector((state) => state.projects);
  const { user } = useAuthentication();
  const userIsAdmin = user?.groups.includes('VENDOR_ONBOARDING-ADMIN');

  const handleChange = (event) => {
    dispatch(projectsActions.setCurrentProject(event));
  };

  const shouldDisplaySelector = () => {
    return shouldDisplaySelectorForAdmin() || shouldDisplaySelectorForUser();
  };

  const shouldDisplaySelectorForAdmin = () => {
    return userIsAdmin && projectsLoaded();
  };

  const projectsLoaded = () => {
    return projects != null && currentProject != null;
  };

  const shouldDisplaySelectorForUser = () => {
    return projectsLoaded() && projects.length > 1;
  };

  if (shouldDisplaySelector()) {
    return (
      <Dropdown
        onClick={() =>
          setTimeout(() => {
            window.scrollBy(0, 1);
          }, 1000)
        }
        id="project-selector"
        label="Project"
        value={currentProject}
        options={projects}
        onChange={handleChange}
        getOptionText={(project) => project.name}
        getOptionId={(project) => project.projectId}
        renderOption={(project) => project.name}
        size="compact"
        style={{
          fontSize: '1rem',
          width: '136px',
          marginLeft: 0,
          overflowWrap: 'anywhere'
        }}
      />
    );
  } else {
    return null;
  }
};

export default ProjectSelector;
