import { Paper, Stack, Typography, Grid, Box } from '@mui/material';
import { Button } from 'cfa-react-components';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import styles from './Registration.module.scss';
import CfaTextField from '../../components/Widget/CfaTextField';
import { useHistory } from 'react-router-dom';
import { api } from '../../helpers/apis';
import toastPromise from '../../helpers/toast-promise';

import cfaLogo from '../../assets/icons/cfa-logo.svg';

const textFieldErrorStyling = styles['cfa-text-field-error'];

const queryParams = new URLSearchParams(window.location.search);
const invitationCode = queryParams.get('invitationCode') || '';
const vendorName = queryParams.get('vendorName') || '';

const pendingHandler = () => 'Registering...';

const successHandler = () =>
  'Check your email for instructions to set your password. After setting your password, return here to login.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);

const Registration = () => {
  const history = useHistory();

  return (
    <div className={styles['box-container']}>
      <Box sx={{ padding: '5pc' }}>
        <Paper elevation={5} sx={{ width: '30pc', height: '52pc' }}>
          <Grid
            container
            alignItems="stretch"
            justifyContent="center"
            direction="column"
            spacing={3}
            px={6}
          >
            <Grid item xs={12} alignItems="center" justifyContent="center">
              <Typography
                variant="h5"
                component="h1"
                mt={6}
                mb={2}
                align="center"
              >
                <div>Vendor Onboarding</div>
                <div>Registration</div>
              </Typography>
            </Grid>

            <Grid item xs={12} alignItems="center" justifyContent="center">
              <img
                className={styles['cfa-logo']}
                src={cfaLogo}
                alt="cfa logo"
              />
            </Grid>
            <Grid item xs={12}>
              <Formik
                initialValues={{
                  firstName: '',
                  lastName: '',
                  vendorName: vendorName,
                  email: '',
                  invitationCode
                }}
                enableReinitialize={true}
                validationSchema={yup.object({
                  firstName: yup.string().required('Required'),
                  lastName: yup.string().required('Required'),
                  vendorName: yup.string().required('Required'),
                  email: yup
                    .string()
                    .email('Invalid email address')
                    .required('Required')
                })}
                onSubmit={async (values, { setSubmitting, isSubmitting }) => {
                  setSubmitting(true);

                  const response = await requestAndNotify(
                    api.post('registrations', values)
                  );

                  setSubmitting(false);

                  response?.status === 200 &&
                    setTimeout(() => history.push('/login'), 1000);
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Stack
                      spacing={1}
                      alignItems="stretch"
                      direction="column"
                      pl={0}
                      ml={0}
                    >
                      <CfaTextField
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        variant="outlined"
                        fullWidth={true}
                        errorClass={textFieldErrorStyling}
                      />
                      <CfaTextField
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        variant="outlined"
                        fullWidth={true}
                        errorClass={textFieldErrorStyling}
                      />
                      <CfaTextField
                        id="vendorName"
                        name="vendorName"
                        label="Vendor Name"
                        variant="outlined"
                        fullWidth={true}
                        errorClass={textFieldErrorStyling}
                        disabled={vendorName !== ''}
                      />
                      <CfaTextField
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        fullWidth={true}
                        errorClass={textFieldErrorStyling}
                      />
                      <Button
                        disabled={isSubmitting}
                        color="primary"
                        type="submit"
                      >
                        Register
                      </Button>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </div>
  );
};

export default Registration;
