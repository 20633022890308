import { useState } from 'react';
import { Button } from 'cfa-react-components';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import styles from './ResetPasswordModal.module.scss';
import * as yup from 'yup';
import { api } from '../../../helpers/apis';
import toastPromise from '../../../helpers/toast-promise';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: `"Apercu-regular", "Roboto", "Helvetica", "Arial" ,sans-serif`
  }
});

const pendingHandler = () => 'Submitting request...';

const successHandler = () =>
  'Check your email for password reset instructions.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);

const validationSchema = yup.object({
  email: yup.string().email('Invalid email address').required('Required')
});

const initialValues = {
  email: ''
};

const ResetPasswordModal = () => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormikSubmit = async (values, { resetForm }) => {
    let response;
    try {
      response = await requestAndNotify(
        api.post('vendors/resetPassword', values)
      );
    } catch (error) {}

    if (response?.status === 200) {
      handleClose();
      resetForm();
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onFormikSubmit
  });

  return (
    <ThemeProvider theme={theme}>
      <div className={styles['page-centered']}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogContent>
            <form onSubmit={formik.handleSubmit}>
              <DialogContentText>
                Enter your email address to receive instructions on how to reset
                your password.
              </DialogContentText>
              <div className={styles['centered-container']}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              <div className={styles['centered-container']}>
                <div>
                  <Button
                    type="button"
                    size="small"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button type="submit" color="primary" size="small">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default ResetPasswordModal;
