export const getStoredSelectedProject = (projects) => {
  let currentProject;
  try {
    let serializedState = localStorage.getItem('onboarding-ui:currentProject');
    if (serializedState !== null) {
      currentProject = JSON.parse(serializedState);
      let currentProjectId = currentProject.projectId;
      currentProject = projects.find((x) => x.projectId === currentProjectId);
      return currentProject;
    }
    return null;
  } catch (err) {}
};
export const saveSelectedProject = (currentProject) => {
  try {
    let serializedProjectState = JSON.stringify(currentProject);
    localStorage.setItem(
      'onboarding-ui:currentProject',
      serializedProjectState
    );
  } catch (err) {}
};
