import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiWithAuth } from '../../helpers/apis';
import { Button, TextField } from 'cfa-react-components';
import Template from '../Template/Template';
import toastPromise from '../../helpers/toast-promise';
import { getProjectInfo } from '../../helpers/project-data';
import CFASpinner from '../../components/Widget/CFASpinner/CFASpinner';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styles from './NonProduction.module.scss';
import {
  useUpdateProjectMutation,
  usePatchProjectMutation
} from '../../store/projectsApi';

const textFieldErrorStyling = styles['cfa-text-field-error'];

const continueToNextStep = async (history) => {
  history.push('/production');
};

const NonProduction = () => {
  const { currentProject } = useSelector((state) => state.projects);
  const isAdmin = useSelector((state) => state.auth.isAdmin);

  const history = useHistory();

  const isLoading = !currentProject;

  const { environmentData, apiName } = getProjectInfo(
    currentProject,
    'NON_PRODUCTION'
  );

  const [updateProject] = useUpdateProjectMutation();
  const [patchProject] = usePatchProjectMutation();

  const pendingHandler = () => 'Going to next step';
  const successHandler = (data) => {
    return 'Project moved to next step';
  };
  const requestAndNotify = toastPromise(pendingHandler, successHandler);

  const operatorUsername = environmentData?.testOperators[0]?.userName;
  const operatorPassword = environmentData?.testOperators[0]?.password;
  const operatorLocation = environmentData?.testOperators[0]?.locationNumber;
  const operatorNumber = environmentData?.testOperators[0]?.operatorNumber;
  const projectRedirectUrl = environmentData?.redirectUrl;

  const missingProjectData = !(
    !!operatorUsername &&
    !!operatorPassword &&
    !!operatorLocation &&
    !!projectRedirectUrl
  );

  const invalidProjectStatus = !(
    currentProject?.projectStatus &&
    currentProject?.projectStatus !== 'STEP_1' &&
    currentProject?.projectStatus !== 'STEP_2'
  );

  const getProjectAudits = async () => {
    if (environmentData) {
      let path =
        'projects/' +
        currentProject.projectId +
        '/data/' +
        environmentData.projectDataId +
        '/audits';
      let auditsResponse = await apiWithAuth.get(path);
      return auditsResponse.data;
    }
    return null;
  };

  const submitRedirectUrl = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await requestAndNotify(
        updateProject({
          currentProjectId: currentProject.projectId,
          values: values
        }).unwrap()
      );
    } catch (error) {}

    setSubmitting(false);
  };

  const proceedToNextStep = async () => {
    const projectAudits = await getProjectAudits();
    if (projectAudits?.length > 0) {
      let request = {
        projectStatus: 'STEP_4'
      };

      try {
        await requestAndNotify(
          patchProject({
            currentProjectId: currentProject.projectId,
            values: request
          })
        );
        history.push('/production');
      } catch (error) {}
    } else {
      toast.error(
        'We are unable to verify that the endpoint has been hit. Please hit the endpoint again and try again.'
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      redirectUrl: '',
      environment: 'NON_PRODUCTION'
    },
    validationSchema: yup.object({
      redirectUrl: yup.string().url('Invalid URL').required('Required')
    }),
    onSubmit: submitRedirectUrl
  });

  const form = (
    <form onSubmit={formik.handleSubmit}>
      <div className={styles['non-prod-body']}>
        <p> Please enter the redirect_uri for your non-prod app here:</p>
        <TextField
          required
          fullWidth
          id="redirectUrl"
          name="redirectUrl"
          label="Okta Redirect URL"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.redirectUrl}
          placeholder="https://..."
        />
        {formik.errors.redirectUrl && formik.touched.redirectUrl ? (
          <div className={textFieldErrorStyling}>
            {formik.errors.redirectUrl}
          </div>
        ) : null}
      </div>

      <div className={styles['non-prod-input-button']}>
        <Button
          style={{ width: '220px' }}
          color="primary"
          type="submit"
          disabled={!(formik.dirty && formik.isValid) || formik.isSubmitting}
        >
          Submit Redirect URL
        </Button>
      </div>
    </form>
  );

  let content;

  if (isLoading) {
    content = <CFASpinner />;
  } else if (invalidProjectStatus && !isAdmin) {
    content = <p>Access is denied to this page.</p>;
  } else if (missingProjectData && !isAdmin && projectRedirectUrl) {
    content = (
      <p>
        Your project is missing some required information. Please contact a CFA
        admin for help.
      </p>
    );
  } else {
    content = (
      <div>
        <div className={styles['non-prod-header']}>3. Non-Production</div>

        <div className={styles['non-prod-greeting']}>
          <p>
            Congratulations, you’re ready to deploy your apps to a hosted
            environment.
          </p>
        </div>

        {!projectRedirectUrl && form}

        {projectRedirectUrl && (
          <div className={styles['non-prod-body']}>
            <p>
              You can now initiate the auth flow from your server and receive
              and store a refresh token for the operator.
            </p>
            <p>
              You will use different operator credentials to login to your
              non-prod application.
            </p>
            <p>
              Please login as operator{' - '}
              <span className={styles['highlight']}>{operatorNumber}</span>
              <br></br>
              username:{' '}
              <span className={styles['highlight']}>{operatorUsername}</span>
              <br></br>
              password:{' '}
              <span className={styles['highlight']}>{operatorPassword}</span>.
            </p>
            <p>
              These credentials will grant you access to data for store location{' '}
              <span className={styles['highlight']}>{operatorLocation}</span>,
              so be sure to use that location in any {apiName} endpoint calls
              your vendor app is making.
            </p>

            {currentProject?.projectStatus === 'STEP_3' && (
              <p>
                When we’ve detected you’ve successfully hit {apiName} endpoints
                in our integration environment for the test operator then click
                the{' '}
                <span className={styles['highlight']}>
                  Proceed to Production
                </span>{' '}
                button below for you to continue.
              </p>
            )}

            {currentProject?.projectStatus === 'STEP_3' && (
              <Button color="primary" onClick={proceedToNextStep}>
                PROCEED TO PRODUCTION STEP
              </Button>
            )}

            {currentProject?.projectStatus !== 'STEP_3' && (
              <Button
                color="primary"
                onClick={() => continueToNextStep(history)}
              >
                CONTINUE
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <Template>
      <div className={styles['non-prod-content']}>{content}</div>
    </Template>
  );
};

export default NonProduction;
