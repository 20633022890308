import { onboardingApi } from './onboardingApi';

const certificationsApi = onboardingApi.injectEndpoints({
  endpoints: (build) => ({
    getCertifications: build.query({
      query: () => ({
        url: `certifications`,
        method: 'get'
      }),
      keepUnusedDataFor: 300,
      providesTags: ['certifications']
    }),
    getVendorCertifications: build.query({
      query: (vendorId) => ({
        url: `vendors/${vendorId}/certifications`,
        method: 'get'
      }),
      keepUnusedDataFor: 300,
      providesTags: ['certifications']
    }),
    postVendorCertification: build.mutation({
      query: (data) => {
        const { vendorId, certification } = data;
        return {
          url: `vendors/${vendorId}/certifications`,
          method: 'post',
          data: { ...certification }
        };
      },
      invalidatesTags: ['certifications', 'users']
    })
  }),
  overrideExisting: false
});

export const {
  useGetCertificationsQuery,
  useGetVendorCertificationsQuery,
  usePostVendorCertificationMutation
} = certificationsApi;
