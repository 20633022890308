import { onboardingApi } from './onboardingApi';

const invitationsApi = onboardingApi.injectEndpoints({
  endpoints: (build) => ({
    getVendorInvitations: build.query({
      query: (vendorId) => ({
        url: `vendors/${vendorId}/invites`,
        method: 'get'
      }),
      providesTags: ['projectInvitations', 'vendorInvitations']
    }),
    getAllVendorInvitations: build.query({
      query: (vendorId) => ({
        url: `vendors/invites`,
        method: 'get'
      })
    }),
    postVendorInvitation: build.mutation({
      query: (data) => ({ url: '/vendors/invites', method: 'post', data })
    }),
    postProjectInvitation: build.mutation({
      query: (data) => {
        const { vendorId, projectId, values } = data;
        return {
          url: `/vendors/${vendorId}/projects/${projectId}/invites`,
          method: 'post',
          data: { ...values }
        };
      },
      invalidatesTags: ['projectInvitations']
    }),
    deleteProjectInvitation: build.mutation({
      query: (data) => {
        const { vendorId, projectId, values } = data;
        return {
          url: `/vendors/${vendorId}/projects/${projectId}/invites`,
          method: 'delete',
          data: { ...values }
        };
      },
      invalidatesTags: ['projectInvitations']
    })
  }),
  overrideExisting: false
});

export const {
  useGetVendorInvitationsQuery,
  useGetAllVendorInvitationsQuery,
  usePostVendorInvitationMutation,
  usePostProjectInvitationMutation,
  useDeleteProjectInvitationMutation
} = invitationsApi;
