import ProjectSideNav from '../../components/Widget/ProjectSideNav';
import Box from '@mui/material/Box';
import styles from './Template.module.scss';

const Template = (props) => {
  return (
    <div style={{ width: '100%' }}>
      <ProjectSideNav />
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
        className={styles.mainContent}
      >
        {props.children}
      </Box>
    </div>
  );
};

export default Template;
