export const getApiCatalogData = () => {
  const teamMemberEndpointData = [
    {
      endpoint: { route: '/locations/{location}/team-members', type: 'GET' },
      scopes: 'team-member-api:read\n' + 'team-member-api:sensitive',
      description:
        'Returns all team members at a location.  The sensitive scope is required to get wage and dob info.'
    },
    {
      endpoint: {
        route: '/locations/{location}/team-members/{personId}',
        type: 'GET'
      },
      scopes: 'team-member-api:read\n' + 'team-member-api:sensitive',
      description: 'Finds a single team member by id.'
    },
    {
      endpoint: { route: '/team-members', type: 'POST' },
      scopes: 'team-member-api:create',
      description: 'Onboards a team member into the CFA payroll system.'
    },
    {
      endpoint: { route: '/addresses/validation', type: 'GET' },
      scopes: 'team-member-api:create',
      description:
        'Address validation endpoint that returns valid city/county matches for a given postal code.'
    }
  ];

  const schedulesEndpointData = [
    {
      endpoint: { route: '/locations/{location}/shifts', type: 'GET' },
      scopes: 'ots-schedule-api:read',
      description:
        'Returns all shifts for a location.  Results can be filtered by shift start and end time or by person id. ' +
        'Paginated responses are returned.'
    },
    {
      endpoint: {
        route: '/locations/{location}/shifts/{shiftId}',
        type: 'GET'
      },
      scopes: 'ots-schedule-api:read',
      description: 'Finds a single shift by id.'
    },
    {
      endpoint: {
        route: '/locations/{location}/availabilities/preferredAvailabilities',
        type: 'GET'
      },
      scopes: 'ots-schedule-api:read',
      description:
        'Returns all team member schedule availabilities for a location.'
    }
  ];

  const retirement401kEndpointData = [
    {
      endpoint: {
        route: '/401k/operators/{operatorNumber}',
        type: 'GET'
      },
      scopes: 'team-member-401k:sensitive',
      description:
        "Returns team members' 401k information associated with an operator. An example payload is provided below."
    }
  ];

  return {
    teamMemberEndpointData,
    schedulesEndpointData,
    retirement401kEndpointData
  };
};
