import TableContainer from '@mui/material/TableContainer';
import styles from '../../pages/Certification/Certification.module.scss';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ApiSelector from './ApiSelector';
import ScopeSelector from './ScopeSelector';
import { Button } from 'cfa-react-components';
import { ReactComponent as SvgComponent } from '../../assets/icons/circle-x-filled.svg';

const deleteIcon = (deleteRow) => <SvgComponent onClick={() => deleteRow()} />;

const emptyRow = (
  <TableRow
    key="empty-row"
    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
  >
    <TableCell align="left">No APIs/scopes currently added</TableCell>
    <TableCell align="right"></TableCell>
    <TableCell></TableCell>
  </TableRow>
);

const renderCertificationRows = (certification) => {
  return certification?.scopes?.map((certification, index) => (
    <TableRow
      key={`${certification.scope.scopeName}-${index}`}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell id={styles['api-column']} align="left">
        {certification.api.name}
      </TableCell>
      <TableCell id={styles['scope-column']} align="left">
        {certification.scope.scopeName}
      </TableCell>
      <TableCell>{''}</TableCell>
    </TableRow>
  ));
};

const renderNewCertificationRows = (
  certificationScopes,
  onApiChange,
  onScopeChange,
  deleteScope,
  addRow
) => {
  return certificationScopes.map((newCertificationScope, index) => (
    <>
      {index + 1 === certificationScopes.length ? (
        <TableRow
          key={`${newCertificationScope.scope?.name}-${index}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="left">
            <ApiSelector
              required
              value={newCertificationScope.api}
              handleChange={onApiChange}
              index={index}
            />
          </TableCell>
          <TableCell align="left">
            <ScopeSelector
              value={newCertificationScope.scope}
              api={newCertificationScope.api}
              handleChange={onScopeChange}
              index={index}
            ></ScopeSelector>
          </TableCell>
          <TableCell>
            <Button
              style={{ backgroundColor: 'white' }}
              variant="outlined"
              color="primary"
              size="md"
              onClick={() => addRow()}
            >
              Add Scope
            </Button>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow
          key={`${newCertificationScope.scope?.name}-${index}`}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell id={styles['api-column']} align="left">
            {newCertificationScope.api.name}
          </TableCell>
          <TableCell id={styles['scope-column']} align="left">
            {newCertificationScope.scope.scopeName}
          </TableCell>
          <TableCell>{deleteIcon(() => deleteScope(index))}</TableCell>
        </TableRow>
      )}
    </>
  ));
};

const CertificationTable = ({
  certification,
  vendorStatus,
  newCertificationScopes,
  setNewCertificationScopes,
  addRow
}) => {
  const emptyNewCertificationScopeObject = { api: '', scope: '' };
  const handleApiChange = (index, value) => {
    if (value) {
      setNewCertificationScopes((prevState) => {
        const newState = [...prevState];
        newState[index] = { scope: '', api: value };
        return newState;
      });
    }
  };
  const handleScopeChange = (index, value) => {
    if (value) {
      setNewCertificationScopes((prevState) => {
        const newState = [...prevState];
        newState[index] = { api: prevState[index].api, scope: value };
        return newState;
      });
    }
  };

  const deleteScope = (scopeIndex) => {
    if (scopeIndex === 0 && newCertificationScopes.length === 1) {
      setNewCertificationScopes([emptyNewCertificationScopeObject]);
    } else {
      let newScopesObj = [...newCertificationScopes];
      let filteredNewScopes = newScopesObj.filter(
        (item, index) => index !== scopeIndex
      );
      setNewCertificationScopes(filteredNewScopes);
    }
  };

  return (
    <>
      <TableContainer className={styles['tableRoot']}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell id={styles['api-name-heading']} align="left">
                Api Name
              </TableCell>
              <TableCell id={styles['scope-name-heading']} align="left">
                Scope Name
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {certification?.scopes?.length === 0 &&
            newCertificationScopes.length === 0 ? (
              emptyRow
            ) : (
              <>
                {renderCertificationRows(certification)}
                {vendorStatus === 'OVERVIEW' &&
                  renderNewCertificationRows(
                    newCertificationScopes,
                    handleApiChange,
                    handleScopeChange,
                    deleteScope,
                    addRow
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default CertificationTable;
