import { TextField } from 'cfa-react-components';
import { useField } from 'formik';

const CfaTextField = ({ errorClass = 'cfa-text-field-error', ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={errorClass}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default CfaTextField;
