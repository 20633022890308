import { Dropdown } from 'cfa-react-components';
import { useGetApisQuery } from '../../store/apisApi';

const ApiSelector = (props) => {
  const { data: apis = [] } = useGetApisQuery();

  const onChange = (event) => {
    props.handleChange(props.index, event);
  };
  if (apis != null) {
    return (
      <Dropdown
        required={props.required}
        id="api-selector"
        label="Api"
        options={apis}
        value={props.value}
        onChange={onChange}
        renderOption={(api) => api.name}
        getOptionId={(api) => api.apiId}
        getOptionText={(api) => api.name}
        size="small"
        style={{ fontSize: '1rem' }}
      />
    );
  } else {
    return null;
  }
};

export default ApiSelector;
