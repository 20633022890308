import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import projectsReducer from './projects';
import apisReducer from './apis';
import certificationsReducer from './certifications';
import onboardingUserReducer from './user';
import { onboardingApi } from './onboardingApi';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    projects: projectsReducer,
    apis: apisReducer,
    certifications: certificationsReducer,
    onboardingUser: onboardingUserReducer,
    [onboardingApi.reducerPath]: onboardingApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(onboardingApi.middleware)
});

export default store;
