import { useEffect, useCallback } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { authActions } from '../store/auth';
import { useDispatch, useSelector } from 'react-redux';
import { clearDataStores } from '../service/DataPopulationService';
import { onboardingApi } from '../store/onboardingApi';

const useAuthentication = () => {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();
  const accessToken = useSelector((state) => state.auth.accessToken);
  const user = useSelector((state) => state.auth.user);
  const { currentProject } = useSelector((state) => state.projects);

  const isAuthenticated = useCallback(
    async () => oktaAuth.isAuthenticated(),
    [oktaAuth]
  );

  useEffect(() => {
    const checkAuth = async () => {
      if (await isAuthenticated()) {
        const { headers, ...userResponse } = await oktaAuth.getUser();
        let accessToken = oktaAuth.getAccessToken();
        dispatch(
          authActions.setAuthState({
            accessToken: accessToken,
            user: userResponse,
            isAdmin: userResponse?.groups.includes('VENDOR_ONBOARDING-ADMIN')
          })
        );
      }
    };
    checkAuth();
  }, [oktaAuth, dispatch, isAuthenticated, currentProject]);

  const logOut = () => {
    oktaAuth.tokenManager.clear();
    dispatch(authActions.clearUserSession());
    dispatch(clearDataStores());
    dispatch(onboardingApi.util.resetApiState());
  };

  return {
    isAuthenticated,
    accessToken,
    user,
    logOut
  };
};

export default useAuthentication;
