import documentationCss from './Documentation.module.scss';
import Template from '../Template/Template';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { apiWithAuth } from '../../helpers/apis';
import parse from 'html-react-parser';

const Documentation = () => {
  const [documentation, setDocumentation] = useState('');

  const { id } = useParams();

  useEffect(() => {
    async function getDocumentation() {
      return await apiWithAuth.get(`projects/documents/${id}`);
    }
    getDocumentation()
      .then((response) => setDocumentation(response.data))
      .catch((error) => {
        toast.error('You do not have access to this documentation');
      });
  }, [id]);

  return (
    <Template>
      <div className={documentationCss.documentation}>
        {parse(documentation, {
          replace: ({ attribs }) =>
            attribs &&
            attribs.class === 'confluence-information-macro-body' && <></>
        })}
      </div>
    </Template>
  );
};

export default Documentation;
