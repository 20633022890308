import { useState } from 'react';
import InvitationModal from '../../components/Widget/InvitationModal/InvitationModal';
import { Link as ReactRouterLink } from 'react-router-dom';
import Template from '../Template/Template';
import Link from '@mui/material/Link';
import styles from './Admin.module.scss';

const Admin = () => {
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <Template>
      <div className={styles['admin-content']}>
        <div align="left">
          <ul>
            <li>
              <Link href="#" underline="none" onClick={handleShow}>
                Invite Vendor
              </Link>
            </li>
            <li>
              <Link to="/pending" component={ReactRouterLink} underline="none">
                Pending Vendor Certifications
              </Link>
            </li>
            <li>
              <Link
                to="/manage-scopes"
                component={ReactRouterLink}
                underline="none"
              >
                Manage Scopes
              </Link>
            </li>
            <li>
              <Link
                to="/allinvites"
                component={ReactRouterLink}
                underline="none"
              >
                All User Invites
              </Link>
            </li>
          </ul>
          <InvitationModal showModal={showModal} handleClose={handleClose} />
        </div>
      </div>
    </Template>
  );
};

export default Admin;
