import { createSlice } from '@reduxjs/toolkit';

const initialOnboardingUserState = { onboardingUser: null };

const onboardingUserSlice = createSlice({
  name: 'onboardingUser',
  initialState: initialOnboardingUserState,
  reducers: {
    setOnboardingUser(state, action) {
      if (state.onboardingUser !== action.payload) {
        state.onboardingUser = action.payload;
      }
    },
    clearUserSession(state) {
      state = initialOnboardingUserState;
    }
  }
});

export const onboardingUserActions = onboardingUserSlice.actions;

export default onboardingUserSlice.reducer;
