import { onboardingApi } from './onboardingApi';

const usersApi = onboardingApi.injectEndpoints({
  endpoints: (build) => ({
    getOnboardingUser: build.query({
      query: (email) => {
        return {
          url: `/users/${email}`,
          method: 'get'
        };
      },
      providesTags: ['users']
    }),
    patchVendor: build.mutation({
      query: (data) => {
        const { vendorId, body } = data;
        return {
          url: `/vendors/${vendorId}`,
          method: 'patch',
          data: { ...body }
        };
      },
      invalidatesTags: ['users', 'certifications']
    })
  }),
  overrideExisting: false
});

export const { useGetOnboardingUserQuery, usePatchVendorMutation } = usersApi;
