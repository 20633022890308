import { createSlice } from '@reduxjs/toolkit';

const initialApisState = { apis: null };

const apisSlice = createSlice({
  name: 'apis',
  initialState: initialApisState,
  reducers: {
    setApis(state, action) {
      if (state.apis !== action.payload) {
        state.apis = action.payload;
      }
    },
    clearUserSession(state) {
      state = initialApisState;
    }
  }
});

export const apisActions = apisSlice.actions;

export default apisSlice.reducer;
