export const getProjectInfo = (currentProject, environment) => {
  const scopes = currentProject?.scopes;
  const apiName = scopes && scopes.length > 0 ? scopes[0].api.name : '';
  const projectData = currentProject?.data;

  const filterEnvironmentData = (project) => {
    const data = project?.filter((envData) => {
      return envData.environment === environment;
    });
    return data ? data[0] : null;
  };

  const getScopesList = () => {
    const list = scopes?.map((scope) => scope.scopeName);
    return list ? `${list.join(' ')}` : [];
  };

  const environmentData = filterEnvironmentData(projectData);
  const scopesList = getScopesList();

  return { environmentData, scopes, scopesList, apiName };
};
