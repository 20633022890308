import { useSelector } from 'react-redux';
import toastPromise from '../../helpers/toast-promise';
import { useCallback, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import CFASpinner from './CFASpinner/CFASpinner';
import CertificationTable from './CertificationTable';
import styles from '../../pages/Certification/Certification.module.scss';
import { TextField, Button } from 'cfa-react-components';
import {
  useGetCertificationsQuery,
  usePostVendorCertificationMutation
} from '../../store/certificationsApi';
import { usePatchVendorMutation } from '../../store/usersApi';
import { useCreateNewProjectMutation } from '../../store/projectsApi';

const pendingHandler = () => 'Saving Certifications...';
const pendingActivateHandler = () => 'Activating Vendor...';
const pendingDocusignHandler = () => 'Updating Vendor...';

const successHandler = () =>
  'The Vendor Certification document for the APIs you have access to will be sent via DocuSign shortly. Once signed you will move to the next step in the onboarding process.';
const successActivateHandler = () =>
  'The Vendor has been activated and project created.';
const successDocusignHandler = () => 'The Vendors status has been updated.';

const requestAndNotify = toastPromise(pendingHandler, successHandler);
const requestAndNotifyActivate = toastPromise(
  pendingActivateHandler,
  successActivateHandler
);
const requestAndNotifyDocusign = toastPromise(
  pendingDocusignHandler,
  successDocusignHandler
);

const emptyNewCertificationScopeObject = { api: '', scope: '' };

const mapScopesFromCertification = (filteredCertification) => {
  if (filteredCertification) {
    let scopes = [];
    filteredCertification.scopes.forEach((scope) => {
      let scopeObj = {};
      scopeObj.api = scope.api;
      scopeObj.scope = scope;
      scopes.push(scopeObj);
    });
    return scopes;
  }
  return [];
};

const mapVendorCertification = (filteredCertification) => {
  let certification = {};
  certification.projectName = filteredCertification.projectName;
  certification.projectDescription = filteredCertification.projectDescription;
  certification.scopes = mapScopesFromCertification(filteredCertification);
  return certification;
};

const areCertificationsAndVendorIdAvailable = (certifications, id) => {
  return (
    certifications !== null && certifications.length !== 0 && id !== undefined
  );
};

const filterVendorCertifications = (certifications, id) => {
  return certifications.filter((certification) => {
    return certification.vendor.vendorId === parseInt(id);
  });
};

const filterProjectCertifications = (vendorCertifications, project) => {
  return vendorCertifications.filter((certification) => {
    return certification.projectName === project.name;
  });
};

const getFilteredCertification = (certifications, id, project) => {
  let filteredCertifications = filterVendorCertifications(certifications, id);
  if (project) {
    filteredCertifications = filterProjectCertifications(
      filteredCertifications,
      project
    );
  }
  if (filteredCertifications.length > 0) {
    return filteredCertifications[0];
  }
  return null;
};

const newRowIsValid = (rows) => {
  const lastRow = rows[rows.length - 1];
  return lastRow.api !== '' && lastRow.scope !== '';
};

const addRow = (setRows) => {
  setRows((prevRows) => {
    return newRowIsValid(prevRows)
      ? [...prevRows, emptyNewCertificationScopeObject]
      : prevRows;
  });
};

const CertificationManager = ({
  vendor,
  project,
  onboardingUser,
  handleClose
}) => {
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [projectDescription, setProjectDescription] = useState('');
  const [projectName, setProjectName] = useState('');
  const [newCertificationScopes, setNewCertificationScopes] = useState([
    emptyNewCertificationScopeObject
  ]);

  const { data: certifications = [], isLoading } = useGetCertificationsQuery();

  const [postVendorCertification] = usePostVendorCertificationMutation();

  const [patchVendor] = usePatchVendorMutation();

  const [createNewProject] = useCreateNewProjectMutation();

  const [certification, setCertification] = useState([]);
  let vendorName = vendor?.name;
  let vendorStatus = vendor?.status;

  const setProjectDetails = (name, description) => {
    setProjectName(name);
    setProjectDescription(description);
  };

  const setProjectDetailsFromCertification = useCallback((certification) => {
    if (certification) {
      setProjectDetails(
        certification.projectName,
        certification.projectDescription
      );
    }
  }, []);

  const clearProjectDetails = useCallback(() => {
    setProjectDetails('', '');
  }, []);

  const filterAndMapCertifications = useCallback(() => {
    if (
      areCertificationsAndVendorIdAvailable(certifications, vendor?.vendorId)
    ) {
      let filteredCert = getFilteredCertification(
        certifications,
        vendor?.vendorId,
        project
      );
      let certification = filteredCert
        ? mapVendorCertification(filteredCert)
        : null;
      setProjectDetailsFromCertification(certification);
      setCertification(certification);
    }
  }, [
    certifications,
    project,
    setProjectDetailsFromCertification,
    vendor?.vendorId
  ]);

  useEffect(() => {
    if ((vendor || project) && certifications) {
      clearProjectDetails();
      filterAndMapCertifications();
    }
  }, [
    vendor,
    project,
    certifications,
    clearProjectDetails,
    filterAndMapCertifications
  ]);

  const handleProjectDescriptionChange = (e) => {
    setProjectDescription(e.target.value);
  };
  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const saveNewCertification = async () => {
    const scopeIds = [];

    newCertificationScopes.forEach((certification) => {
      scopeIds.push(certification.scope.scopeId);
    });

    const body = {
      projectName: projectName,
      projectDescription: projectDescription,
      scopeIds: scopeIds
    };

    try {
      await requestAndNotify(
        postVendorCertification({
          vendorId: vendor.vendorId,
          certification: body
        }).unwrap()
      );
      setNewCertificationScopes([]);
    } catch (error) {}
  };

  const docusignSent = async () => {
    let request = {
      vendorStatus: 'AWAITING_SIGNATURE'
    };

    try {
      await requestAndNotifyDocusign(
        patchVendor({
          vendorId: vendor.vendorId,
          body: request
        })
      );
      handleClose();
    } catch (error) {}
  };

  const activateVendor = async () => {
    let scopeIds = certification.scopes.map(
      (certification) => certification.scope.scopeId
    );

    let vendorUser = vendor.users[0];

    let request = {
      vendorId: vendor.vendorId,
      projectName: projectName !== '' ? projectName : certification.projectName,
      projectDescription:
        projectDescription !== ''
          ? projectDescription
          : certification.projectDescription,
      contactName: vendorUser.firstName + ' ' + vendorUser.lastName,
      contactEmail: vendorUser.email,
      scopeIds: scopeIds
    };

    try {
      await requestAndNotifyActivate(
        createNewProject({
          ...request
        }).unwrap()
      );
      handleClose();
    } catch (error) {}
  };

  const vendorStatusIsOverview = vendorStatus === 'OVERVIEW';

  if (projectName === '' && vendorStatusIsOverview) {
    setProjectName(vendorName);
  }

  const certificationInputFields = (
    <>
      <div className={styles['certification-headline']}>Certification</div>
      <div className={styles['certification-vendor']}>
        <TextField
          label="Vendor Name"
          value={vendorName || ''}
          readOnly={true}
        />
        <TextField label="Status" value={vendorStatus || ''} readOnly={true} />
      </div>
      <div className={styles['certification-project-name']}>
        <TextField
          required={vendorStatusIsOverview}
          maxLength={30}
          label="Project Name"
          value={projectName}
          disabled={!vendorStatusIsOverview}
          onChange={(event) => {
            return vendorStatusIsOverview
              ? handleProjectNameChange(event)
              : null;
          }}
        />
      </div>
      <div className={styles['certification-project-description']}>
        <TextField
          rows={5}
          fullWidth
          multiline
          maxLength={255}
          label="Project Description"
          required={vendorStatusIsOverview}
          value={projectDescription}
          disabled={!vendorStatusIsOverview}
          onChange={(event) => {
            return vendorStatusIsOverview
              ? handleProjectDescriptionChange(event)
              : null;
          }}
        />
      </div>
    </>
  );

  return isLoading ? (
    <CFASpinner />
  ) : (
    <div>
      {certificationInputFields}

      <CertificationTable
        certification={certification}
        vendorStatus={vendorStatus}
        newCertificationScopes={newCertificationScopes}
        setNewCertificationScopes={setNewCertificationScopes}
        addRow={() => addRow(setNewCertificationScopes)}
      ></CertificationTable>

      <Stack spacing={2} direction="row">
        {vendorStatusIsOverview && (
          <Button
            color="primary"
            onClick={saveNewCertification}
            disabled={newCertificationScopes.length < 2}
          >
            Save Certification
          </Button>
        )}
        {vendorStatus === 'CERTIFICATION_REQUESTED' && isAdmin && (
          <div>
            <Button color="primary" onClick={docusignSent}>
              Docusign Sent
            </Button>
          </div>
        )}
        {vendorStatus === 'AWAITING_SIGNATURE' && isAdmin && (
          <div>
            <Button color="primary" onClick={activateVendor}>
              Activate Vendor
            </Button>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default CertificationManager;
