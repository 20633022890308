import { createSlice } from '@reduxjs/toolkit';

const initialCertificationsState = { certifications: null };

const certificationsSlice = createSlice({
  name: 'certifications',
  initialState: initialCertificationsState,
  reducers: {
    setCertifications(state, action) {
      if (state.certifications !== action.payload) {
        state.certifications = action.payload;
      }
    },
    clearUserSession(state) {
      state = initialCertificationsState;
    }
  }
});

export const certificationsActions = certificationsSlice.actions;

export default certificationsSlice.reducer;
