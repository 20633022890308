import animationData from '../../../assets/animations/CFASpinner.json';
import styles from './CFASpinner.module.scss';
import Lottie from 'react-lottie-player/dist/LottiePlayerLight';

const CFASpinner = () => {
  return (
    <div className={styles['page-centered']}>
      <Lottie
        loop
        animationData={animationData}
        play
        style={{ width: 400, height: 400 }}
        rendererSettings={{ preserveAspectRatio: 'xMidYMid slice' }}
      />
    </div>
  );
};

export default CFASpinner;
