import { apiWithAuth } from '../helpers/apis';
import { projectsActions } from '../store/projects';
import { apisActions } from '../store/apis';
import { certificationsActions } from '../store/certifications';
import { onboardingUserActions } from '../store/user';
import { getStoredSelectedProject } from './LocalStorageService';

export const populateInitialDataStores = (user) => {
  return async (dispatch) => {
    await populateProjects(dispatch);
    await populateApis(dispatch);
    await populateCertifications(dispatch);
    await populateUser(user.email, dispatch);
  };
};

const populateUser = async (email, dispatch) => {
  const path = '/users/' + email;
  const userResponse = await apiWithAuth.get(path);
  dispatch(onboardingUserActions.setOnboardingUser(userResponse.data));
};

const populateCertifications = async (dispatch) => {
  const path = '/certifications';
  const certificationsResponse = await apiWithAuth.get(path);
  dispatch(
    certificationsActions.setCertifications(certificationsResponse.data)
  );
};

export const clearDataStores = () => {
  return (dispatch) => {
    dispatch(projectsActions.clearUserSession(null));
    dispatch(apisActions.clearUserSession(null));
    dispatch(certificationsActions.clearUserSession(null));
    dispatch(onboardingUserActions.clearUserSession(null));
  };
};

const getCurrentProject = (projects) => {
  let storedSelectedProject = getStoredSelectedProject(projects);
  if (storedSelectedProject !== undefined && storedSelectedProject !== null) {
    return storedSelectedProject;
  }
  let currentProject =
    projects !== null && projects.length >= 1 ? projects[0] : null;
  return currentProject;
};

const populateProjects = async (dispatch) => {
  const path = `/projects`;
  const projectsResponse = await apiWithAuth.get(path);
  let projects = projectsResponse.data;
  let currentProject = getCurrentProject(projects);
  dispatch(projectsActions.setProjects(projects));
  dispatch(projectsActions.setCurrentProject(currentProject));
};

const populateApis = async (dispatch) => {
  const path = `/apis`;
  const apisResponse = await apiWithAuth.get(path);
  let apis = apisResponse.data;
  dispatch(apisActions.setApis(apis));
};
